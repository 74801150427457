
export const formattedPrice = (price: number, currency: string, float = 0) => {
    const formated = price.toFixed(float);
    if (currency == 'EUR') {
        return formated + ' €';
    }
    else if (currency == 'USD') {
        return '$' + formated;
    }
    else if (currency === null) {
        return formated;
    }
    return formated + ' ' + currency;
  }
  