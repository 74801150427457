import {
  HANDLE_ADD_TO_FAVORITES_ITEMS,
  HANDLE_ADD_TO_FAVORITES_OUTFIT,
  HANDLE_REMOVE_TO_FAVORITES_ITEMS,
  HANDLE_REMOVE_TO_FAVORITES_OUTFIT,
  SWITCH_FAVORITES_CONTENT,
} from '../actions'

import { getQueryValue } from 'src/utils/query';

const loadFromLocalStorage = (field) => {
  try {
    const serialized = localStorage.getItem(field)
    if (serialized !== null) {
      const unserialized = JSON.parse(serialized)
      if (Array.isArray(unserialized)) {
        return unserialized
      }
    }
  }
  catch (e) {
    // ---- Cannot use localStorage ----
  }
  return []
}

const saveIntoLocalStorage = (field, value) => {
  try {
    localStorage.setItem(field, JSON.stringify(value))
  }
  catch (e) {
    // ---- Cannot use localStorage ----
  }
  return value
}

const domain = getQueryValue('domain') || 'undefined'

export const initialState: State.Favorites = {
  outfits: loadFromLocalStorage(`${domain}.favorites.outfits`),
  items: loadFromLocalStorage(`${domain}.favorites.items`),
  isOutfit: false,
}

const GarmentReducers = (
  state: State.Favorites = initialState,
  action: Types.Action
): State.Favorites => {
  switch (action.type) {
    case HANDLE_ADD_TO_FAVORITES_ITEMS:
      return {
        ...state,
        items: saveIntoLocalStorage(`${domain}.favorites.items`, action.payload),
        isOutfit: (state.isOutfit && !state.outfits.length) ? false : true,
      }
    case HANDLE_REMOVE_TO_FAVORITES_ITEMS:
      return {
        ...state,
        items: saveIntoLocalStorage(`${domain}.favorites.items`, action.payload),
      }

    case HANDLE_ADD_TO_FAVORITES_OUTFIT:
      return {
        ...state,
        outfits: saveIntoLocalStorage(`${domain}.favorites.outfits`, action.payload),
        isOutfit: (!state.isOutfit && !state.items.length) ? true : false,
      }
    case HANDLE_REMOVE_TO_FAVORITES_OUTFIT:
      return {
        ...state,
        outfits: saveIntoLocalStorage(`${domain}.favorites.outfits`, action.payload),
      }

    case SWITCH_FAVORITES_CONTENT:
      return {
        ...state,
        isOutfit: action.payload,
      }
    default:
      return state
  }
}

export default GarmentReducers
