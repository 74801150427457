import { Dispatch } from 'redux'
import { GetAllModelsService } from '../../services/modelProduct'

import { FETCH_ALL_MODELS } from './index'

export const FetchAllModelAction: Types.ActionFactory<State.ModelProduct> = (payload) => ({
  type: FETCH_ALL_MODELS,
  payload,
})

export const GetAllModelsCall = () => async (dispatch: Dispatch<Types.Action>) => {
  const res: any = await GetAllModelsService()
  if ([200, 201, 204].includes(res.status)) {
    dispatch(FetchAllModelAction(res.data))
    return true
  }
  return false
}
