import { HANDLE_FILTERS_MODEL, HANDLE_FILTERS_CATALOG } from '../actions'

export const initialState: State.Filters = {
  model: {
    gender: 'male',
    minHeight: 151,
    maxHeight: 189,
    size: [],
    skinColor: '',
    hairColor: '',
  },
  catalog: {
    sortBy: 'male',
    productType: 0,
    color: 0,
    size: '',
  },
}

const FiltersReducers = (
  state: State.Filters = initialState,
  action: Types.Action
): State.Filters => {
  switch (action.type) {
    case HANDLE_FILTERS_MODEL:
      return {
        ...state,
        model: action.payload,
      }
    case HANDLE_FILTERS_CATALOG:
      return {
        ...state,
        catalog: action.payload,
      }
    default:
      return state
  }
}

export default FiltersReducers
