import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'

import store from './store/index'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'antd/dist/antd.variable.min.css'
import './styles/styles.scss'

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider prefixCls='custom'>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
