import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Collapse from 'antd/lib/collapse'
import Slider from 'antd/lib/slider'

import { DownOutlined } from '@ant-design/icons'
import { ChangeFiltersModelAction } from 'src/store/actions/filters'
import useCustomTranslation from 'src/utils/translation'
const { Panel } = Collapse

const CollpaseSlider: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const filters = useSelector((state: State.Root) => state.filters)
  const { minHeight, maxHeight } = filters.model

  const onChange = (value: any) => {
    const newData: any = { ...filters.model }
    newData.minHeight = value[0]
    newData.maxHeight = value[1]
    dispatch(ChangeFiltersModelAction(newData))
  }

  return (
    <div className='collapse'>
      <Collapse
        expandIconPosition='right'
        className='collapse--container'
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={t('filter.fake_size')} key='1'>
          <Slider
            range
            onChange={(value) => onChange(value)}
            value={[minHeight, maxHeight]}
            min={130}
            max={200}
            className='collapse--slider'
          />
          <div className='collapse--bubble-container'>
            <div className='collapse--bubble'>{`${minHeight} cm`}</div>
            <div className='collapse--bubble'>{`${maxHeight} cm`} </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default CollpaseSlider
