
export const getQueryValue = (key, defaultValue = null) => {
  const locationParams = new URLSearchParams(window.location.search)
  const keyValue = locationParams.get(key)
  return keyValue !== null ? keyValue : defaultValue
}

export const getQueryLocale = () => {
  return getQueryValue('locale', 'fr')
}
