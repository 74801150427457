
export const ENVIRONMENTS: any = {
  development: process.env.NODE_ENV === 'development',
  production: process.env.NODE_ENV === 'production',
}

export const API_ENDPOINT: string = process.env.API_ENDPOINT || process.env.REACT_APP_API_ENDPOINT

export const DEBUG_MODE: boolean = process.env.DEBUG_MODE === 'true' || process.env.REACT_APP_DEBUG_MODE === 'true'

export function getCommonHeaders(): Types.ValueMap<string> {
  return {
    'Content-Type': 'application/json',
    'Accept-Language': 'fr',
  }
}

export function getFormDataHeaders(): Types.ValueMap<string> {
  return {
    'Content-Type': 'multipart/form-data',
  }
}
