// @ts-nocheck
import React, { useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'

import { GetCompanyProfileCall } from './store/actions/profile'
import { GetAllModelsCall } from './store/actions/modelProduct'
import { handleCompleteLoaderAction, handleStartLoaderAction } from './store/actions/loader'

import HomePage from './pages'
import ProductPage from './pages/product'
import ModelPage from './pages/model'
import FavoritesPage from './pages/favorites'
import CartPage from './pages/cart'

import Layout from './containers/layout/layout'

import { getQueryValue } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'

import 'src/i18n/config'
import SwipePage from './pages/swipe'
import Loader from './components/Loader'

import history from './utils/history'
import { DEBUG_MODE } from './settings/global'

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const company = useSelector((state: State.Root) => state.profile?.company)
  const loader = useSelector((state: State.Root) => state.loader)

  const styleOverride = !DEBUG_MODE ? null : ``

  useEffect(() => {
    const fetchData = async () => {
      dispatch(handleStartLoaderAction())
      const promises = [dispatch(GetCompanyProfileCall()), dispatch(GetAllModelsCall())]
      await Promise.all(promises)
      dispatch(handleCompleteLoaderAction())
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return history.listen((location) => {
      if (inIframe()) {
        const messageData = {
          pathname: location.pathname,
        }
        // ---- Remettre les champs de store/index ----
        for (const key of ['look_id', 'type']) {
          const value = getQueryValue(key)
          if (value !== null) {
            messageData[key] = value
          }
        }
        sendIframeMessage('veesual_location', messageData)
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // ---- Primary color surchargee en url ----
    let primaryColor = getQueryValue('primary_color')
    if (typeof primaryColor === 'string' && primaryColor.match(/^0+$/)) {
      primaryColor = 'black'
    }

    // ---- Chargement du theme si present dans la config ----
    if (company && company.custom_view) {
      const { custom_colors } = company
      ConfigProvider.config({
        theme: {
          primaryColor: primaryColor || custom_colors.primary_color,
          infoColor: custom_colors.info_color,
          successColor: custom_colors.success_color,
          processingColor: custom_colors.processing_color,
          errorColor: custom_colors.error_color,
          warningColor: custom_colors.warning_color,
        },
      })
      const root = document.documentElement
      root?.style.setProperty('--scrollbar-color', primaryColor || custom_colors.primary_color)
    }
    // ---- Sinon on force au moins la primary color dans le theme ----
    else if (primaryColor) {
      ConfigProvider.config({
        theme: {
          primaryColor: primaryColor,
        },
      })
      const root = document.documentElement
      root?.style.setProperty('--scrollbar-color', primaryColor)
    }
  }, [company])

  const domain = getQueryValue('domain')
  const fontCss =
    domain == 'marinehenrion.com'
      ? './marinehenrion/font.css'
      : domain == 'christmas.com'
      ? './christmas/font.css'
      : domain == 'farfetch.com'
      ? './farfetch/font.css'
      : domain == 'facilenfil.fr'
      ? './facilenfil/font.css'
      : null

  if (!loader.isComplete) {
    return (
      <div className='App'>
        {fontCss && <link rel='stylesheet' href={fontCss} />}
        <Loader />
      </div>
    )
  }

  return (
    <div className='App'>
      {(styleOverride || company.override_style) && (
        <style
          dangerouslySetInnerHTML={{ __html: styleOverride || company.override_style }}
        ></style>
      )}

      {fontCss && <link rel='stylesheet' href={fontCss} />}
      <Router history={history}>
        <Switch>
          <Route exact path='/swipe'>
            {company?.enable_swipe ? <SwipePage /> : <Redirect to={'/' + window.location.search} />}
          </Route>
          <Layout>
            <Route exact path='/'>
              <HomePage />
            </Route>
            <Route exact path='/product'>
              <ProductPage />
            </Route>
            <Route exact path='/model'>
              <ModelPage />
            </Route>
            <Route exact path='/favorites'>
              <FavoritesPage />
            </Route>
            <Route exact path='/cart'>
              <CartPage />
            </Route>
          </Layout>
        </Switch>
      </Router>
    </div>
  )
}

export default App
