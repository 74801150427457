import React from 'react'

import { resizeImage } from 'src/utils/image'

interface CardCarouselGarmentProps {
  garment: Models.Garment,
  hide: boolean,
  cardHeight?: number,
}

const CardCarouselGarment: React.FunctionComponent<CardCarouselGarmentProps> = (props) => {
  const {garment, hide, cardHeight} = props

  // FIXME: ajout du width car si le slick se charge alors qu'au moins une image n'est pas chargee, alors rien ne s'affiche
  return (
    <div className={hide ? 'card-carousel--hide' : 'card-carousel card-carousel--swipe'} style={cardHeight && {height: cardHeight, width: cardHeight}}>
      <img
        className='card-carousel--swipe-image'
        src={resizeImage(garment.image_clipping_url || garment.image_url, {width: 800})}
      />
    </div>
  )
}

export default CardCarouselGarment
