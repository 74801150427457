import axios from 'axios'

import { API_ENDPOINT, getCommonHeaders } from '../settings/global'

import { getQueryValue } from 'src/utils/query'

const apiPrefix: string = getQueryValue('api_prefix', '/v1/marinehenrion')
const apiKey: string = getQueryValue('api_key', '5396f7a3918ab76fd8d014765a68bb84cabdad7b764f574cf9db68111e1f8890f22924efdf1ee65ace9b-8e4d384c0f5ee0705e3f98bf66d5c42c')

const customAxios = axios.create({
  baseURL: (API_ENDPOINT || `https://api.veesual.ai`) + (!apiPrefix.match(/^\//) ? '/' : '') + apiPrefix,
  timeout: 10000,
  headers: getCommonHeaders(),
})

const requestHandler = (request) => {
  request.headers.Authorization = 'Bearer ' + apiKey
  return request
}

const responseHandler = (response) => {
  if (response.status === 401) console.log('unauthorized')
  return response
}

const errorHandler = (error) => {
  console.error('axios errorHandler', error)
  return Promise.reject(error)
}

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default customAxios
