// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Row from 'antd/lib/row'
import Tabs from 'antd/lib/tabs'
import Button from 'antd/lib/button'

import { GetAllGarmentsCall } from 'src/store/actions/garment'

import { SettingOutlined } from '@ant-design/icons'

import { handleSidebarAction } from 'src/store/actions/sidebar'

import { trackEvent } from 'src/utils/tracking';
import useCustomTranslation from 'src/utils/translation'

const { TabPane } = Tabs

interface MenuTabProps {
  withFilters: boolean
  style?: any
}

const MenuTab: React.FunctionComponent<MenuTabProps> = (props) => {
  const dispatch = useDispatch()
  const [current, setCurrent] = useState('TOP')
  const garmentType = useSelector((state: State.Root) => state.garment?.type)
  const company = useSelector((state: State.Root) => state.profile?.company)
  const { withFilters, style } = props

  const { t } = useCustomTranslation()

  useEffect(() => {
    if (garmentType && garmentType != current) {
      setCurrent(garmentType)
    }
    // eslint-disable-next-line
  }, [garmentType])

  const handleTypeClick = (value) => {
    trackEvent('Type Clicked', {catalog_type: value}, 'Menu')
    setCurrent(value)
    if (value != garmentType) {
      dispatch(GetAllGarmentsCall(value))
    }
  }

  const handleFilterClick = () => {
    trackEvent('Filter Clicked', {catalog_type: current, filter_type: 'item'}, 'Menu')
    dispatch(handleSidebarAction('/catalog'))
  }

  const MenuOption = [
    { label: t('layout.top'), value: 'TOP' },
    { label: t('layout.bottom'), value: 'BOTTOM' },
    { label: t('layout.dress'), value: 'DRESS' },
    { label: t('layout.outerwear'), value: 'OUTERWEAR' },
    { label: t('layout.other'), value: 'OTHER' },
  ]

  return (
    <>
      {location.pathname === '/' && (
        <Row className='tabs tabs--container'>
          <Tabs activeKey={current} onTabClick={handleTypeClick} style={style}>
            {MenuOption &&
              MenuOption.map(
                (item: { label: string; value: string }) =>
                  (!company || company?.garment_types?.indexOf(item.value) !== -1) && (
                    <TabPane tab={item.label} key={item.value} />
                  )
              )}
          </Tabs>

          {withFilters && (
            <Button
              onClick={handleFilterClick}
              icon={<SettingOutlined />}
              type='link'
              className='button'
            ></Button>
          )}
        </Row>
      )}
    </>
  )
}

export default MenuTab
