// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import parse from 'html-react-parser'

import { GetGarmentDetailCall } from 'src/store/actions/garment'

import ImagePreviewProduct from 'src/components/image/ImagePreview'

import FilterSelect from 'src/components/select/filter'
import { LoadingOutlined } from '@ant-design/icons'

import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart } from 'src/utils/garment'
import { trackPage, trackEvent } from 'src/utils/tracking';

const ProductsPage: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const garment = useSelector((state: State.Root) => state.garment?.current)
  const detail = useSelector((state: State.Root) => state.garment?.detail)
  const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
  const [currentSize, setCurrentSize] = useState(null)
  const [addingToCart, setAddingToCart] = useState(false)

  useEffect(() => {
    trackPage()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchData = async (garmentId) => {
      await dispatch(GetGarmentDetailCall(garmentId))
    }
    if (garment && (!detail || detail.garment_id != garment.garment_id)) {
      fetchData(garment.garment_id)
    }
    // eslint-disable-next-line
  }, [garment])

  if (!garment || !detail) {
    return <div className='product'></div>
  }

  const optionSize = getGarmentOptionSizes(detail)

  const handleSizeChange = (value) => {
    trackEvent('Size Selected', [garment, {item_size_selected: value}], 'Item Detail')
    setCurrentSize(value)
  }

  const handleAddToCart = () => {
    trackEvent('Item Added to cart', [garment, {item_size_selected: currentSize}], 'Item Detail')
    setAddingToCart(true)
    addGarmentSizeToCart(garment, currentSize, (success) => {
      if (!success) {
        console.error(`Timeout when adding to cart`)
      }
      setAddingToCart(false)
    })
  }

  return (
    <div className='product'>
      <div className='layout--title'>
        <h1 className='title title--h1 title'>{t('product.title')}</h1>
      </div>
      <Row className='product--container'>
        <Col className='product--left-side' xs={{ span: 24 }} xl={{ span: 10 }}>
          <ImagePreviewProduct garment={detail} />
        </Col>
        <Col className='product--right-side' xs={{ span: 24 }} xl={{ span: 14 }}>
          {/*detail.product_brand && (
            <h2 className='product--brand'>{parse(detail.product_brand)}</h2>
          )*/}
          <h2 className='product--title'>{parse(detail.product_name)}</h2>
          {(detail.product_price_original && detail.product_price_original > detail.product_price) && (
            <h2 className='product--price--promotion'>
              <span className='product--price--original'>{formattedPrice(detail.product_price_original, detail.product_currency, priceFloat)}</span>
              <span className='product--price--percent'>-{Math.round((detail.product_price_original - detail.product_price) * 100 / detail.product_price_original)}%</span>
            </h2>
          )}
          <h2 className='product--price'>{formattedPrice(detail.product_price, detail.product_currency, priceFloat)}</h2>
          <p className='product--text'>{parse(detail.product_description_html ? detail.product_description_html : detail.product_description)}</p>
          <div className='product--select'>
            <FilterSelect
              options={optionSize}
              translation={false}
              placeholder={t('product.choose_size')}
              value={currentSize}
              onChange={handleSizeChange}
            />
          </div>
          <div className='product--button'>
            <Button className='button card--dark' disabled={currentSize === null || addingToCart} onClick={handleAddToCart}>
              {addingToCart ? <LoadingOutlined /> : t('product.add_to_cart')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ProductsPage
