import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import Card from 'antd/lib/card'
import Typography from 'antd/lib/typography'
import Button from 'antd/lib/button'

import { GetLookCall } from '../../store/actions/look'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking';

import { HeartOutlined, HeartFilled, LoadingOutlined } from '@ant-design/icons'

import FilterSelect from 'src/components/select/filter'

const { Title, Paragraph } = Typography

interface CardFavoritesProps {
  data: any
  favorites: any
  isOutfit: boolean
  addOnFavorites(value: any): void
  removeFromFavorites(item: any): void
  ratio: number
}

const checkIsFavorites = (favoritesList: any[], current: any, isOutfit: boolean) => {
  const field = isOutfit ? 'look_id' : 'garment_id'
  const res = favoritesList.find((item: any) => item[field] === current[field])
  return res
}

const CardFavorites: React.FunctionComponent<CardFavoritesProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useCustomTranslation()
  const { data, favorites, isOutfit, addOnFavorites, removeFromFavorites, ratio } = props
  const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
  const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)
  const [currentSize, setCurrentSize] = useState(null)
  const [addingToCart, setAddingToCart] = useState(false)

  const isFavorites = !checkIsFavorites(favorites, data, isOutfit)
  const optionSize = isOutfit ? [] : getGarmentOptionSizes(data)

  const imageUrl = isOutfit ? data.image_urls[0] : (
    useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url 
  )

  const handleCardClick = () => {
    if (isOutfit) {
      trackEvent('Outfit Clicked', data, 'Favorite')
      dispatch(GetLookCall(data.look_id))
    }
    else {
      trackEvent('Item Clicked', data, 'Favorite')
      dispatch(GetLookCall({[data.garment_type]: data.garment_id}))
    }
  }

  const handleSizeChange = (value) => {
    trackEvent('Size Selected', [data, {item_size_selected: value}], 'Favorite')
    setCurrentSize(value)
  }

  const handleAddToCart = (e) => {
    e.stopPropagation()
    trackEvent('Item Added to cart', [data, {item_size_selected: currentSize}], 'Favorite')
    setAddingToCart(true)
    addGarmentSizeToCart(data, currentSize, (success) => {
      if (!success) {
        console.error(`Timeout when adding to cart`)
      }
      setAddingToCart(false)
    })
  }

  const handleAddLookToCart = (e) => {
    e.stopPropagation()
    trackEvent('Outfit Detailed', data, 'Favorite')
    dispatch(GetLookCall(data.look_id))
    history.push('/cart'+window.location.search, {from: 'favorites'})
  }

  const handleAddToFavorite = (e) => {
    e.stopPropagation()
    if (isOutfit) {
      trackEvent('Outfit Saved to favorite', data, 'Favorite')
    }
    else {
      trackEvent('Item Saved to favorite', data, 'Favorite')
    }
    addOnFavorites(data)
  }

  const handleRemoveFromFavorite = (e) => {
    e.stopPropagation()
    if (isOutfit) {
      trackEvent('Outfit Removed from favorite', data, 'Favorite')
    }
    else {
      trackEvent('Item Removed from favorite', data, 'Favorite')
    }
    removeFromFavorites(data)
  }

  return (
    <Card
      className='card card--container override_card_container'
      hoverable
      onClick={handleCardClick}
      cover={
        <div className='card card--image-container'>
          <div className='card--image'>
            <div
              className='card--background override_img_container'
              style={{ backgroundImage: `url(${resizeImage(imageUrl, {width: 800})})`, paddingBottom: `${ratio}%` }}
            />
          </div>
        </div>
      }
    >
      {!isOutfit ? (
        <>
          <Title
            ellipsis={{
              rows: 1,
            }}
            className='title title--h3 title--center card--title'
          >{parse(data.product_name)}</Title>
          <Paragraph
            ellipsis={{
              rows: 2,
            }}
            className='text text--center text--small card--text'
          >{parse(data.product_description)}</Paragraph>
          {(data.product_price_original && data.product_price_original > data.product_price) && (
          <Title
            ellipsis={{
              rows: 1,
            }}
            className='title title--center card--title card--price--promotion'
          >
            <span className='card--price--original'>{formattedPrice(data.product_price_original, data.product_currency, priceFloat)}</span>
            <span className='card--price--percent'>-{Math.round((data.product_price_original - data.product_price) * 100 / data.product_price_original)}%</span>
          </Title>
        )}
          <Title
            ellipsis={{
              rows: 1,
            }}
            className='title title--center title--h2 card--title'
          >
            {formattedPrice(data.product_price, data.product_currency, priceFloat)}
          </Title>
          <div className='card card--favorites_options'>
            <FilterSelect
              name='size'
              onChange={handleSizeChange}
              placeholder={t('product.size')}
              translation={false}
              value={currentSize}
              options={optionSize}
            />
            <Button className='button card--dark' disabled={currentSize === null || addingToCart} onClick={handleAddToCart}>
              {addingToCart ? <LoadingOutlined /> : t('product.add_to_cart')}
            </Button>
          </div>
        </>
      ) : (
        <div className='card--outfit'>
          <Button className='button card--dark' onClick={handleAddLookToCart}>{t('look.add_to_cart')}</Button>
        </div>
      )}

      {isFavorites ? (
        <Button
          onClick={handleAddToFavorite}
          icon={<HeartOutlined />}
          type='link'
          className='button card--favorite'
        ></Button>
      ) : (
        <Button
          icon={<HeartFilled />}
          type='link'
          className='button card--favorite'
          onClick={handleRemoveFromFavorite}
        ></Button>
      )}
    </Card>
  )
}

export default CardFavorites
