import { Dispatch } from 'redux'
import { GetLookService } from '../../services/look'
import { FetchCurrentGarmentAction } from './garment'
import { getQueryValue } from 'src/utils/query'
import eventBus from 'src/utils/eventBus';

import { INC_LOOK_LOADING, SET_QUERY_LOOK_ID, FETCH_CURRENT_LOOK, SET_LOOK_INDEX } from './index'

export const IncLookLoadingAction: Types.ActionFactory<number> = (payload) => ({
  type: INC_LOOK_LOADING,
  payload,
})

export const SetQueryLookIdAction: Types.ActionFactory<string> = (payload) => ({
  type: SET_QUERY_LOOK_ID,
  payload,
})

export const FetchCurrentLookAction: Types.ActionFactory<State.Look> = (payload) => ({
  type: FETCH_CURRENT_LOOK,
  payload,
})

export const SetLookIndexAction: Types.ActionFactory<number> = (payload) => ({
  type: SET_LOOK_INDEX,
  payload,
})

export const GetLookCall = (params: any) => async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
  // ---- Recuperation du look ----
  const look = getState().look?.current

  // ---- Construction des params ----
  let changed = !look;
  const finalParams: any = {}
  if (typeof(params) === 'string') {
    if (!look || look.look_id != params) {
      finalParams.look_id = params
      changed = true;
    }
  }
  else if (typeof params === 'object' && !Array.isArray(params) && params !== null) {
    for (const field in params) {
      let parsedField = field
      if (['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR'].indexOf(field) !== -1) {
        parsedField = `${field.toLowerCase()}_garment_id`
      }
      let parsedValue = params[field]
      if (parsedField == 'outerwear_garment_id' && parsedValue == '__REMOVE__') {
        parsedValue = null
      }
      if (parsedValue) {
        finalParams[parsedField] = parsedValue
      }
    }
    if (Object.keys(params).length >= 1 && look) {
      if (!finalParams.model_id && look.model) {
        finalParams['model_id'] = look.model.model_id
      }
      if (!finalParams.top_garment_id && look.top && !finalParams['dress_garment_id']) {
        finalParams['top_garment_id'] = look.top.garment_id
      }
      if (!finalParams.bottom_garment_id && look.bottom && !finalParams['dress_garment_id']) {
        finalParams['bottom_garment_id'] = look.bottom.garment_id
      }
      if (!finalParams.dress_garment_id && look.dress && !finalParams['top_garment_id'] && !finalParams['bottom_garment_id']) {
        finalParams['dress_garment_id'] = look.dress.garment_id
      }
      if (!finalParams.outerwear_garment_id && look.outerwear && !params['outerwear_garment_id']) {
        finalParams['outerwear_garment_id'] = look.outerwear.garment_id
      }
      if (!finalParams.mode && look.mode && !params['mode']) {
        finalParams['mode'] = look.mode
      }
    }
    if (!look
      || (finalParams['model_id'] && (!look.model || finalParams['model_id'] != look.model.model_id))
      || (finalParams['top_garment_id'] && (!look.top || finalParams['top_garment_id'] != look.top.garment_id))
      || (finalParams['bottom_garment_id'] && (!look.bottom || finalParams['bottom_garment_id'] != look.bottom.garment_id))
      || (finalParams['dress_garment_id'] && (!look.dress || finalParams['dress_garment_id'] != look.dress.garment_id))
      || (finalParams['outerwear_garment_id'] && (!look.outerwear || finalParams['outerwear_garment_id'] != look.outerwear.garment_id))
      || (!finalParams['outerwear_garment_id'] && look.outerwear)
      || (finalParams['mode'] && (!look.mode || finalParams['mode'] != look.mode))) {
        changed = true;
    }
  }
  else if (params === null) {
    for (const field of ['model_id', 'garment_id']) {
      if (getQueryValue(field) !== null) {
        finalParams[field] = getQueryValue(field);
      }
    }
  }

  // ---- Seulement si on a un changement ----
  if (changed) {
    // ---- Loading ----
    dispatch(IncLookLoadingAction(1))

    // ---- Process ----
    eventBus.dispatch('pinHeader', {})
    const res: any = await GetLookService(finalParams)
    if ([200, 201, 204].includes(res.status)) {
      dispatch(FetchCurrentLookAction(res.data))

      const lookIndex = getState().look?.index
      if (!res.data.image_urls || typeof(res.data.image_urls[lookIndex]) === 'undefined') {
        dispatch(SetLookIndexAction(0))
      }

      const type = getState().garment?.type
      if (type && res.data[type.toLowerCase()]) {
        dispatch(FetchCurrentGarmentAction(res.data[type.toLowerCase()]))
      }
      else {
        dispatch(FetchCurrentGarmentAction(null))
      }

      dispatch(IncLookLoadingAction(-1))
      return true
    }

    // ---- Fin du loading ----
    dispatch(IncLookLoadingAction(-1))
  }
  return false
}

export const GetLookFromQueryCall = (lookId: any) => async (dispatch: Dispatch<Types.Action>) => {
  dispatch(SetQueryLookIdAction(lookId))
  // @ts-ignore
  dispatch(GetLookCall(lookId ? {look_id: lookId} : null))
}
