import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './en/translation.json';
import frTranslation from './fr/translation.json';

import { DEBUG_MODE } from '../settings/global'

import { getQueryLocale } from 'src/utils/query';

const locale = getQueryLocale()
let resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  }
}
let fallback = 'fr'
if (typeof(locale) === 'string') {
  const lowerLocale = locale.toLowerCase()
  if (['fr', 'en'].indexOf(lowerLocale) !== -1) {
    resources = {[lowerLocale]: resources[lowerLocale]}
    fallback = lowerLocale
  }
  else if (locale === 'label') {
    resources = {}
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: DEBUG_MODE,
    fallbackLng: fallback,
    resources: resources,
  })

export default i18n
