
import { inIframe, sendIframeMessage, sendIframeMessageWithResponse } from 'src/utils/iframe'
import { getQueryValue } from 'src/utils/query'

export const getGarmentSizeLabelValue = (size: Models.GarmentSize) => {
  const label = typeof(size.label) === 'string' ? size.label.toUpperCase() : size.label
  return {label: label, value: size.value}
}

export const getGarmentOptionSizes = (garment: Models.Garment) => {
  return garment.product_sizes.map(size => getGarmentSizeLabelValue(size))
}

export const addGarmentSizesToCart = (params: any[], callback: (success: boolean) => void) => {
  // ---- Debug ----
  for (const one of params) {
    console.log(one.garment.garment_id, one.currentSize)
  }

  // ---- En fonction du client ----
  if (inIframe() && getQueryValue('domain') == 'marinehenrion.com') {
    // ---- Initialisation ----
    const payloads = []

    // ---- Pour chaque param ----
    for (const one of params) {
      // ---- Raccourci ----
      const { garment, currentSize } = one

      // ---- Recuperation du variation_id ----
      let variation_id = null
      for (const size of garment.product_sizes) {
        if (size.value == currentSize && typeof (size.variation_id) !== 'undefined') {
          variation_id = size.variation_id
        }
      }

      // ---- Ajout au payloads ----
      payloads.push({
        type: 'request',
        payload: {
          method: 'POST',
          url: 'https://marinehenrion.com',
          data: {
            quantity: 1,
            'add-to-cart': garment.product_external_id,
            product_id: garment.product_external_id,
            variation_id: variation_id,
          },
        },
      })
    }

    // ---- Refresh du cart ----
    payloads.push({
      type: 'javascript',
      payload: `jQuery(document.body).trigger('wc_fragment_refresh');`
    })

    // ---- Lancement du message avec callback ----
    sendIframeMessageWithResponse('veesual_execute', payloads, (responses) => {
      if (responses === false) {
        return callback(false);
      }
      return callback(true);
    }, params.length * 10000)
  }
  // ---- Operation de noel ----
  else if (getQueryValue('domain') == 'christmas.com') {
    // ---- Ouverture de lien externe si possible ----
    if (params.length != 1) {
      alert(`This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`)
    }
    else {
      alert(`This is a demo experience. Get in touch with Veesual to implement the cart! We wish you a merry Christmas.`)
      window.open(params[0].garment.product_external_url, '_blank');
    }

    // ---- Callback ----
    callback(true)
  }
  // ---- La redoute ----
  else if (inIframe() && getQueryValue('domain') == 'laredoute.fr') {
    // ---- Initialisation ----
    const payloads = []

    // ---- Pour chaque param ----
    for (const one of params) {
      // ---- Raccourci ----
      const { garment, currentSize } = one

      // ---- Recuperation du variation_id ----
      let offer_id = null, category_id = null
      for (const size of garment.product_sizes) {
        if (size.value == currentSize && typeof(size.offer_id) !== 'undefined') {
          offer_id = size.offer_id
          if (typeof(size.category_id) !== 'undefined') {
            category_id = size.category_id
          }
        }
      }

      // ---- Ajout au payloads ----
      payloads.push({
        type: 'request',
        payload: {
          method: 'POST',
          url: '/shoppingbasket/additemtocart',
          data: {
            quant: "1",
            basketSize: "",
            presCode: "",
            itemOfferId: offer_id,
            contextCategoryId: category_id,
            productId: garment.product_external_id,
            loyaltySource: "pdp",
            origin: "treestructureflyout",
          },
        },
      })
    }

    // ---- Ajout au payloads ----
    payloads.push({
      type: 'request',
      payload: {
        method: 'GET',
        url: '/shoppingbasket/basketitemcount/?_=' + Date.now(),
      },
    })

    // ---- Lancement du message avec callback ----
    sendIframeMessageWithResponse('veesual_execute', payloads, (responses) => {
      if (responses === false) {
        return callback(false);
      }
      const itemCount = parseInt(responses.pop());
      sendIframeMessage('veesual_execute', [{
        type: 'javascript',
        payload: `jQuery('#basketIcon .badge').text(${itemCount});`
      }]);
      return callback(true);
    }, params.length * 5000)
  }
  // ---- Default ----
  else {
    setTimeout(() => callback(true), 2000)
  }
}

export const addGarmentSizeToCart = (garment: Models.Garment, currentSize: string, callback: (success: boolean) => void) => {
  addGarmentSizesToCart([{garment, currentSize}], callback)
}

