// @ts-nocheck
import ReactGA from 'react-ga';
import { DEBUG_MODE } from 'src/settings/global'
import { getQueryValue, getQueryLocale } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'
import store from 'src/store'

const enableAnalytics = window.location.hostname !== 'localhost'
const trackingDebug = DEBUG_MODE || !window.location.hostname.match(/^([^.]+\.)?experience\.veesual\.ai$/)

const hasAnalytics = () => {
  return typeof(analytics) !== 'undefined'
}

const hasGoogleAnalytics = () => {
  const googleAnalytics = store.getState().profile?.company?.google_analytics
  if (googleAnalytics !== null) {
    if ((Array.isArray(googleAnalytics) && googleAnalytics.length) || (typeof googleAnalytics === 'string' && googleAnalytics !== '')) {
      return true
    }
  }
  return false
}

let areAnalyticsLoaded = false
let areGoogleAnalyticsLoaded = false
const initTracking = () => {
  // ---- Segment ----
  if (hasAnalytics()) {
    if (enableAnalytics) {
      if (trackingDebug) {
        analytics.load("FJrMmCOpG1J7OcTcRYzeKnC4SxHuxsIR");
      }
      else {
        analytics.load("DxRiz6VlK1AxKi1oV3NaaBpp4HBrqlYW");
      }
    }
    areAnalyticsLoaded = true
  }

  // ---- GA ----
  if (hasGoogleAnalytics()) {
    if (enableAnalytics) {
      const googleAnalytics = store.getState().profile?.company?.google_analytics
      if (googleAnalytics !== null) {
        ReactGA.initialize(googleAnalytics, {debug: trackingDebug})
      }
    }
    areGoogleAnalyticsLoaded = true
  }
}

const getBasicProperties = () => {
  let origin = getQueryValue('origin')
  if (origin !== null) {
    const originSplit = origin.split('?')
    if (originSplit.length == 2) {
      let originQuery = originSplit[1]
      originQuery = originQuery.replace(/(^|&)(utm_[^&#=]*|fbclid|gclid|gclsrc|_ga|mc_cid|mc_eid|_branch_match_id)(=[^&#]*)?/gi, '')
      originQuery = originQuery.replace(/^&/, '')
      origin = originSplit[0] + (originQuery != '' ? '?' + originQuery : '')
    }
  }

  const extras = {}
  for (let i = 1; i <= 5; i++) {
    const extraField = `extra_param${i > 1 ? i : ''}`
    const extraValue = getQueryValue(extraField)
    if (extraValue !== null && extraValue !== '') {
      extras[extraField] = extraValue
    }
  }

  const state = store.getState()
  return Object.assign({
    client_id: state.profile.company.id,
    client_internal: state.profile.company.internal,
    client_name: state.profile.company.name,
    locale: getQueryLocale(),
    domain: getQueryValue('domain'),
    experience: getQueryValue('experience'),
    origin: origin,
  }, extras)
}

const getPageNameAndProperties = (path = null) => {
  const finalPath = path !== null ? path : window.location.pathname
  switch (finalPath) {
    case '/': return { name: 'Home', properties: {} }
    case '/model': return { name: 'Model Choice', properties: {} }
    case '/product': return { name: 'Item Detail', properties: {} }
    case '/favorites':
      const state = store.getState()
      return { name: 'Favorite', properties: { favorite_type: state.favorites.isOutfit ? 'outfit' : 'item' }}
    case '/cart': return { name: 'Outfit Detail', properties: {} }
    case '/swipe': return { name: 'Swipe', properties: {} }
  }
  return { name: 'Unknown', properties: {} }
}

export const getPageName = (path = null) => {
  return getPageNameAndProperties(path).name
}

const doTrackPage = async () => {
  // ---- Ajout des basics ----
  const pageAndProperties = getPageNameAndProperties()
  const finalProperties = Object.assign(getBasicProperties(), pageAndProperties.properties)

  // ---- Debug ----
  if (trackingDebug) {
    console.log('trackPage', pageAndProperties.name, finalProperties)
  }

  // ---- Lancement de l'event ----
  if (enableAnalytics) {
    if (areAnalyticsLoaded) {
      analytics.page(null, pageAndProperties.name, finalProperties)
    }
    if (areGoogleAnalyticsLoaded) {
      ReactGA.pageview(window.location.pathname, pageAndProperties.name);
    }
  }
}

const addPrefixToProperties = (prefix, properties: any) => {
  const prefixed = {}
  for (const key in properties) {
    prefixed[prefix + key] = properties[key]
  }
  return prefixed
}

const convertProperties = (properties?: any) => {
  // ---- Traduction des properties ----
  let convertedProperties = {}
  if (properties !== undefined && properties !== null) {
    if (Array.isArray(properties)) {
      for (const one of properties) {
        convertedProperties = Object.assign(convertedProperties, convertProperties(one))
      }
    }
    else if (typeof(properties.garment_id) !== 'undefined') {
      convertedProperties = {
        item_internal_id: properties.garment_id,
        item_external_id: properties.product_external_id || null,
        item_name: properties.product_name,
        item_type: properties.garment_type,
        item_price: properties.product_price,
      }
    }
    else if (typeof(properties.model_id) !== 'undefined') {
      convertedProperties = {
        model_id: properties.model_id,
        model_name: properties.model_name,
        model_height: properties.model_height,
        model_garment_size: properties.model_garment_size,
      }
    }
    else if (typeof(properties.look_id) !== 'undefined') {
      let nbItems = 0
      let totalPrice = 0
      for (const type of ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']) {
        const typeLower = type.toLowerCase()
        if (properties[typeLower]) {
          nbItems += 1
          totalPrice += properties[typeLower].product_price
        }
      }
      convertedProperties = Object.assign({
        outfit_id: properties.look_id,
        outfit_nb_items: nbItems,
        outfit_total_price: totalPrice,
      }, convertProperties(properties.model))
      for (const type of ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']) {
        const typeLower = type.toLowerCase()
        if (properties[typeLower]) {
          convertedProperties = Object.assign(convertedProperties, addPrefixToProperties(typeLower + '_', convertProperties(properties[typeLower])))
        }
      }
    }
    else if (typeof(properties) === 'object') {
      convertedProperties = properties
    }
  }
  return convertedProperties
}

const convertFinalProperties = (name: string, properties: Record<string, any>) => {
  // ---- Surcharge globale si besoin ----
  if (name === 'Item Added to cart') {
    return Object.assign(properties, {revenue: properties.item_price, currency: 'EUR'})
  }
  
  return properties
}

const doTrackEvent = async (name: string, properties?: any, category: string = null) => {
  // ---- Traduction des properties ----
  const convertedProperties = convertProperties(properties)
  const pageAndProperties = getPageNameAndProperties()
  const convertedPageProperties = Object.assign({ page_view: pageAndProperties.name }, pageAndProperties.properties)
  const convertedCategory = { category: category }

  // ---- Ajout des basics ----
  const finalProperties = convertFinalProperties(name, Object.assign(convertedCategory, getBasicProperties(), convertedPageProperties, convertedProperties))

  // ---- Debug ----
  if (trackingDebug) {
    console.log('trackEvent', name, finalProperties)
  }

  // ---- Lancement de l'event ----
  if (enableAnalytics) {
    if (areAnalyticsLoaded) {
      analytics.track(name, finalProperties)
      if (inIframe() && finalProperties.client_internal == 'laredoute') {
        // ---- Envoi d'une liste restreinte d'events ----
        if (['Init', 'Outfit Created', 'Swipe left', 'Swipe right', 'Item Added to cart', 'Item Saved to favorite', 'Choose model Clicked'].indexOf(name) !== -1) {
          sendIframeMessage('veesual_execute', [{
            type: 'javascript',
            payload: `window.dispatchEvent(new CustomEvent('veesual_event:${name.replace(/'/, "\\'")}', {detail: JSON.parse('${JSON.stringify(finalProperties).replace(/'/, "\\'")}')}));`,
          }])
        }
      }
    }
    if (areGoogleAnalyticsLoaded) {
      ReactGA.event({
        category: finalProperties.category,
        action: name,
      })
    }
  }
}

const canUseAnalytics = () => {
  if (hasAnalytics() || hasGoogleAnalytics()) {
    if (!areAnalyticsLoaded && !areGoogleAnalyticsLoaded) {
      initTracking()
      doTrackEvent('Init', null, 'Experience')
    }
    return true
  }
  return false
}

export const trackPage = async () => {
  if (canUseAnalytics()) {
    doTrackPage()
  }
}

export const trackEvent = async (name: string, properties?: any, category: string = null) => {
  if (canUseAnalytics()) {
    doTrackEvent(name, properties, category)
  }
}