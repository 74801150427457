import ApiInstance from './index'

import { getQueryLocale } from 'src/utils/query';

const LOOK_ENDPOINT = `/look`

// GET LOOK

const GetLookService = async (
  params: API.Req.GetLook
): Promise<API.Resp.GetLook> => {
  try {
    return await ApiInstance.get(`${LOOK_ENDPOINT}`, { params: Object.assign(params, { locale: getQueryLocale() }) })
  } catch (error) {
    return error.response
  }
}

export { GetLookService }
