
export const breadcrumbRoutes = (values: string[], t: any, search = false) => {
  // ---- Toutes les possibilites ----
  const allRoutes = {
    '/': t('breadcrumb.catalog'),
    '/favorites': t('breadcrumb.favorites'),
    '/cart': t('breadcrumb.look_detail'),
    '/product': t('breadcrumb.product_detail'),
    '/model': t('breadcrumb.choose_model'),
  }

  // ---- Reconstruction ----
  const routes = []
  for (const path of values) {
    routes.push({path: path+(search ? window.location.search : ''), breadcrumbName: allRoutes[path] || t('breadcrumb.unknown')})
  }

  return routes
}
