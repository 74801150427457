import { combineReducers } from 'redux'

import model from './modelProduct'
import garment from './garment'
import look from './look'
import profile from './profile'
import modal from './modal'
import loader from './loader'
import favorites from './favorites'
import filters from './filters'
import sidebar from './sidebar'
import swipe from './swipe'

export default combineReducers({
  model,
  garment,
  look,
  profile,
  modal,
  loader,
  favorites,
  filters,
  sidebar,
  swipe,
})
