import { OPEN_MODAL } from '../actions'

export const initialState: State.Modal = {
  category: {},
  open: false,
}

const ModalReducers = (state: State.Modal = initialState, action: Types.Action): State.Modal => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        category: action.payload.category,
      }
    default:
      return state
  }
}

export default ModalReducers
