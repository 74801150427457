// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'

import CardCart from 'src/components/card/cardCart'

const { Title, Paragraph } = Typography
import { LoadingOutlined } from '@ant-design/icons'

import { formattedPrice } from 'src/utils/price'
import { addGarmentSizesToCart } from 'src/utils/garment'
import { trackPage, trackEvent } from 'src/utils/tracking';
import CardCartSwipe from 'src/components/card/cardCartSwipe'

interface CartPageProps {
  swipeStyle?: boolean
}

const CartPage: React.FunctionComponent<CartPageProps> = (props) => {
  const { t } = useCustomTranslation()
  const look = useSelector((state: State.Root) => state.look?.current)
  const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
  const company = useSelector((state: State.Root) => state.profile?.company)
  const [sizes, setSizes] = useState({})
  const [addingToCart, setAddingToCart] = useState(false)
  const { swipeStyle } = props

  const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

  useEffect(() => {
    if (!swipeStyle) {
      trackPage()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newSizes = {}
    for (const type of ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']) {
      const typeLower = type.toLowerCase()
      if (sizes[type] && look[typeLower]) {
        newSizes[type] = sizes[type]
      }
    }
    setSizes(newSizes)
    // eslint-disable-next-line
  }, [look])

  if (!look) {
    return <div className='cart cart--container'>
      <div className='cart--empty'>{t('cart.no_products')}</div>
    </div>
  }

  let nbSelected = 0
  let totalPrice = 0
  let currency = null
  for (const type of ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']) {
    const typeLower = type.toLowerCase()
    if (sizes[type] && look[typeLower]) {
      nbSelected += 1
      totalPrice += look[typeLower].product_price
    }
    if (look[typeLower] && currency === null) {
      currency = look[typeLower].product_currency
    }
  }

  const handleClear = () => {
    trackEvent('Delete all Clicked', [look, {outfit_nb_items_selected: nbSelected, outfit_total_price_selected: totalPrice}], 'Outfit Detail')
    setSizes({})
  }

  const handleAddToCart = () => {
    const params = []
    for (const type of ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']) {
      const typeLower = type.toLowerCase()
      if (sizes[type]) {
        trackEvent('Item Added to cart', [look[typeLower], {item_size_selected: sizes[type]}], 'Outfit Detail')
        params.push({ garment: look[typeLower], currentSize: sizes[type] })
      }
    }
    if (params.length) {
      trackEvent('Outfit Added to cart', [look, {outfit_nb_items_selected: nbSelected, outfit_total_price_selected: totalPrice}], 'Outfit Detail')
      setAddingToCart(true)
      addGarmentSizesToCart(params, (success) => {
        if (!success) {
          console.error(`Timeout when adding to cart`);
        }
        setAddingToCart(false)
      })
    }
  }

  return (
    <div className='cart cart--container'>
      <Row>
        <Title
          ellipsis={{
            rows: 1,
          }}
          className='title title--h2 cart--item'
        >
          {swipeStyle ? t('cart.detail'): t('cart.outfit')}
        </Title>
      </Row>
      <Row className='cart'>
        {['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR'].map(type => {
          const garment = look[type.toLowerCase()]
          if (garment) {
            return (
              swipeStyle ?
              <CardCartSwipe ratio={garmentImgRatio} key={garment.garment_id} garment={garment} value={sizes[type]} onChange={(e) => setSizes({...sizes, [type]: e})} />
              :
              <CardCart ratio={garmentImgRatio} key={garment.garment_id} garment={garment} value={sizes[type]} onChange={(e) => setSizes({...sizes, [type]: e})} />
            )
          }
        })}
      </Row>
      <Row className='cart cart--total' style={swipeStyle && {paddingLeft: 0}}>
        <div className='cart cart--text'>
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className='text text--small'
            style={{marginBottom: 0}}
          >{`${nbSelected > 1 ? t('cart.item_selected') : t('cart.item_selected_singular')}: ${nbSelected}`}</Paragraph>
          <Title
            ellipsis={{
              rows: 1,
            }}
            className='title title--h2'
          >{formattedPrice(totalPrice, currency, priceFloat)}</Title>
        </div>
        <div className='cart cart--button'>
          <Button onClick={handleClear} disabled={!nbSelected} ghost className='button card--light'>
            {t('cart.clear_all')}
          </Button>
          <Button
            className='button card--dark'
            disabled={Object.keys(sizes).length === 0 || addingToCart}
            onClick={handleAddToCart}
          >
            {addingToCart ? <LoadingOutlined /> : t('cart.add_to_cart')}
          </Button>
        </div>
      </Row>
    </div>
  )
}

export default CartPage
