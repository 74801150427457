import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Row from 'antd/lib/row'
import Button from 'antd/lib/button'

import { CloseOutlined } from '@ant-design/icons'

import FilterSelect from '../../components/select/filter'
import { optionsColor, optionsSkinColor, optionsGender } from 'src/utils/data/js/options'
import CollpaseCheckbox from 'src/components/collapse/CollapseCheckbox'
import CollapseSlider from 'src/components/collapse/CollapseSlider'
import { ChangeFiltersModelAction } from 'src/store/actions/filters'
import { handleSidebarAction } from 'src/store/actions/sidebar'

interface FiltersModelProps {
  mobile: boolean
}

const FiltersModel: React.FunctionComponent<FiltersModelProps> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const filters = useSelector((state: State.Root) => state.filters)

  const onChange = async (value: any, name: string) => {
    const newData: any = { ...filters.model }
    newData[name] = value
    await dispatch(ChangeFiltersModelAction(newData))
  }

  const onChangeCheckbox = (value: any) => {
    const newData: any = { ...filters.model }
    const newList: any = [...filters.model.size]
    if (filters.model.size.includes(value)) {
      const currentIndex = newList.findIndex((item) => item === value)
      if (currentIndex >= 0) newList.splice(currentIndex, 1)
    } else newList.push(value)
    newData.size = newList
    dispatch(ChangeFiltersModelAction(newData))
  }

  const onClose = () => {
    const historyState = (history.location?.state as any)
    if (historyState && historyState.from == 'swipe') {
      history.push('/swipe'+window.location.search)
    }
    else {
      history.push('/'+window.location.search)
    }
  }

  return (
    <Row gutter={24} justify='center' className='filters-model filters-model--container'>
      <Row className='filters-model--head'>
        <h2 className='filters-model--title'>{t('filter.title_model')}</h2>
        {props.mobile ? (
          <CloseOutlined onClick={() => dispatch(handleSidebarAction())} />
        ) : (
          <Button
            onClick={onClose}
            ghost
            className='button card--light filters-model--button'
          >
            {t('filter.close')}
          </Button>
        )}
      </Row>
      <Row className='filters-model--content'>
        <FilterSelect
          placeholder={t('filter.fake_gender')}
          onChange={onChange}
          name='gender'
          options={optionsGender}
        />
        <CollapseSlider />
        <CollpaseCheckbox onChangeCheckbox={onChangeCheckbox} value={filters.model.size} />
        <FilterSelect
          placeholder={t('filter.fake_hair_color')}
          onChange={onChange}
          name='hairColor'
          options={optionsColor}
        />
        <FilterSelect
          placeholder={t('filter.fake_skin_color')}
          onChange={onChange}
          name='skinColor'
          options={optionsSkinColor}
        />
      </Row>
    </Row>
  )
}

export default FiltersModel
