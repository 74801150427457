import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCustomTranslation from 'src/utils/translation'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import Card from 'antd/lib/card'

import { FetchCurrentGarmentAction, GetAllGarmentsCall } from 'src/store/actions/garment'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking';

interface ThumbnailProps {
  look: Models.Look
}

const Thumbnail: React.FunctionComponent<ThumbnailProps> = (props) => {
  const look = props.look
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useCustomTranslation()
  const [typeState, setTypeState] = useState('')
  const garmentType = useSelector((state: State.Root) => state.garment?.type)
  const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)

  const getGarmentImageUrl = (garment) => {
    return useClipping && garment.image_clipping_url ? garment.image_clipping_url : garment.image_url
  }

  const handleCardClick = (type) => {
    trackEvent('Item Selected', [look, look[type.toLowerCase()]], 'Outfit')
    setTypeState(type)
    if (garmentType != type) {
      dispatch(GetAllGarmentsCall(type))
    }
    dispatch(FetchCurrentGarmentAction(look[type.toLowerCase()]))
  }

  const handleReplaceClick = (e, type) => {
    e.stopPropagation()
    trackEvent('Item Replaced', [look, look[type.toLowerCase()]], 'Outfit')
    history.push('/'+window.location.search)
  }

  const handleSeeProductClick = (e, type) => {
    e.stopPropagation()
    trackEvent('Item Detailed', [look, look[type.toLowerCase()]], 'Outfit')
    history.push('/product'+window.location.search)
  }

  return (
    <>
      <Row className='thumbnail thumbnail--row'>
        {['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR'].map(type => {
          const garment = look[type.toLowerCase()]
          if (garment) {
            return (
              <Card
                hoverable
                bordered={false}
                key={garment.garment_id}
                className={'thumbnail--item override_img_container'}
                onClick={() => handleCardClick(type)}
                cover={
                  <div className='thumbnail thumbnail--image-container'>
                    <div
                      className={
                        typeState === type ? 'thumbnail--image thumbnail--blur' : 'thumbnail--image'
                      }
                    >
                      <div
                        className='thumbnail--background'
                        style={{ backgroundImage: `url(${resizeImage(getGarmentImageUrl(garment), {width: 800})})` }}
                      />
                    </div>
                    {typeState === type && (
                      <div className='thumbnail--image-blur'>
                        <div className='thumbnail--actions'>
                          <Button
                            ghost
                            className='button thumbnail--light'
                            onClick={(e) => handleReplaceClick(e, type)}
                          >
                            {t('look.replace')}
                          </Button>
                          <Button
                            className='button thumbnail--dark'
                            onClick={(e) => handleSeeProductClick(e, type)}
                          >
                            {t('look.see_product')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                }
              ></Card>
            )
          }
        })}
      </Row>
    </>
  )
}

export default Thumbnail
