import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunkMiddleware from 'redux-thunk'
import ReduxQuerySync from 'redux-query-sync'

import history from '../utils/history'

import rootReducer from './reducers'
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})
const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunkMiddleware)
    // other store enhancers if any
  )
)

import { GetAllGarmentsFromQueryCall } from 'src/store/actions/garment'
import { GetLookFromQueryCall } from 'src/store/actions/look'

ReduxQuerySync({
  store,
  params: {
    // ---- A garder en sync avec App.tsx ----
    type: {
      selector: state => state.garment?.queryType,
      action: value => GetAllGarmentsFromQueryCall(value),
      stringToValue: string => string,
      valueToString: value => `${value}`,
      defaultValue: 'TOP',
    },
    look_id: {
      selector: state => state.look?.queryLookId,
      action: value => GetLookFromQueryCall(value),
      stringToValue: string => string,
      valueToString: value => `${value}`,
      defaultValue: undefined,
    },
  },
  initialTruth: 'location',
  replaceState: true,
  history,
})

export default store
