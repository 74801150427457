import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import FilterSelect from '../../components/select/filter'
import { optionsFilter, optionSize, optionsColor, optionsType } from 'src/utils/data/js/options'
import { ChangeFiltersCatalogAction } from 'src/store/actions/filters'

const Filters: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const filters = useSelector((state: State.Root) => state.filters)
  const garmentType = useSelector((state: State.Root) => state.garment?.type)
  const [current, setCurrent] = useState('TOP')

  useEffect(() => {
    if (garmentType && garmentType != current) {
      setCurrent(garmentType)
    }
    // eslint-disable-next-line
  }, [garmentType])

  const onChange = async (value: any, name: string) => {
    const newData: any = { ...filters.catalog }
    newData[name] = value
    await dispatch(ChangeFiltersCatalogAction(newData))
  }

  return (
    <>
      <Row gutter={24} justify='center' className='filters filters--container'>
        <Col className='gutter-row' span={5}>
          <FilterSelect
            onChange={onChange}
            name='sortBy'
            placeholder={t('filter.fake_sort')}
            options={optionsFilter}
          />
        </Col>
        <Col className='gutter-row' span={5}>
          <FilterSelect
            onChange={onChange}
            name='productType'
            placeholder={t('filter.fake_product_type')}
            options={optionsType}
          />
        </Col>
        <Col className='gutter-row' span={5}>
          <FilterSelect
            onChange={onChange}
            name='color'
            placeholder={t('filter.fake_color')}
            options={optionsColor}
          />
        </Col>
        <Col className='gutter-row' span={5}>
          <FilterSelect name='size' onChange={onChange} placeholder={t('filter.fake_size')} options={optionSize} />
        </Col>
      </Row>
    </>
  )
}

export default Filters
