import React, { useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'

import Image from 'antd/lib/image'

import { SearchOutlined } from '@ant-design/icons'

import { trackEvent } from 'src/utils/tracking';

interface ImagePreviewProductProps {
  garment: Models.GarmentDetail
  favorites: Models.Garment[]
  addOnFavorites(value: Models.Garment): void
  removeFromFavorites(item: Models.Garment): void
}

const ImagePreviewProduct: React.FunctionComponent<ImagePreviewProductProps> = (props) => {
  const { garment } = props
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(0)

  const handleZoomClick = (e) => {
    e.stopPropagation()
    trackEvent('Zoom Opened', [garment, {item_index_selected: current}], 'Item Detail')
    setVisible(true)
  }

  const handleDotClick = (e, key) => {
    e.stopPropagation()
    trackEvent('Carousel Selected', [garment, {item_index_selected: current, carousel_type: 'dot'}], 'Item Detail')
    setCurrent(key)
  }

  const handleThumbnailClick = (e, key) => {
    e.stopPropagation()
    trackEvent('Carousel Selected', [garment, {item_index_selected: current, carousel_type: 'thumbnail'}], 'Item Detail')
    setCurrent(key)
  }

  return (
    <>
      <Col className='image image--preview-container'>
        <div className='image--preview-relative override_img_container'>
          <Image
            preview={false}
            className='image--preview'
            src={garment.image_urls[current]}
            onClick={handleZoomClick}
          />
          <div className='card-model--bottom'>
            <Button
              icon={<SearchOutlined />}
              type='link'
              className='button'
              onClick={handleZoomClick}
            ></Button>
          </div>
        </div>

        <div style={{ display: 'none' }}>
          <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
            <Image src={garment.image_urls[current]} />
            {garment.image_urls.length > 1 && garment.image_urls.map((imageItem: string, key: number) => (
              key !== current && (
                <Image src={imageItem} key={key} />
              )
            ))}
          </Image.PreviewGroup>
        </div>
      </Col>

      {garment.image_urls.length > 1 && (
        <>
          <Row className='image--dot-container'>
            {garment.image_urls.map((item: any, key: number) => (
              <div
                key={key}
                className={`image--dot ${key === current ? 'image--dot-active' : ''}`}
                onClick={(e) => handleDotClick(e, key)}
              />
            ))}
          </Row>
          <Row className='image--row'>
            {garment.image_urls.map((item: any, key: number) => (
              <div key={key} className='image--minimize override_img_container' onClick={(e) => handleThumbnailClick(e, key)}>
                <img src={item} />
              </div>
            ))}
          </Row>
        </>
      )}
    </>
  )
}

export default ImagePreviewProduct
