import { FETCH_COMPANY_PROFILE } from '../actions'

export const initialState: State.Profile = {
  company: null,
}

const ProfileReducers = (
  state: State.Profile = initialState,
  action: Types.Action
): State.Profile => {
  switch (action.type) {
    case FETCH_COMPANY_PROFILE:
      return {
        ...state,
        company: action.payload,
      }
    default:
      return state
  }
}

export default ProfileReducers
