// @ts-nocheck
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Tabs from 'antd/lib/tabs'

import FavoritesCatalog from 'src/containers/catalog/FavoritesCatalog'
import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'

const { TabPane } = Tabs

import { trackPage, trackEvent } from 'src/utils/tracking';

const FavoritesPage: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const favorites = useSelector((state: State.Root) => state.favorites)

  useEffect(() => {
    trackPage()
    // eslint-disable-next-line
  }, [favorites.isOutfit])

  const handleFavoritesList = (value: any) => {
    trackEvent('Favorite type Clicked', {favorite_type: value == '1' ? 'outfit' : 'item', favorite_count: value == '1' ? favorites?.outfits?.length : favorites?.items?.length}, 'Menu')
    dispatch(SwitchFavoritesContentAction(value === '1'))
  }

  return (
    <div className='favorites favorites--container'>
      <div className='layout--title'>
        <h1 className='title title--h1 title'>{t('favorite.title')}</h1>
      </div>
      <div className='favorites--pane'>
        <Tabs activeKey={favorites.isOutfit ? '1' : '2'} onTabClick={handleFavoritesList}>
          <TabPane tab={`${t('favorite.saved_outfits')} (${favorites?.outfits?.length})`} key='1' />
          <TabPane tab={`${t('favorite.saved_items')} (${favorites?.items?.length})`} key='2' />
        </Tabs>
      </div>

      <FavoritesCatalog />
    </div>
  )
}

export default FavoritesPage
