import React from 'react'
import Collapse from 'antd/lib/collapse'
import Checkbox from 'antd/lib/checkbox'

import { DownOutlined } from '@ant-design/icons'
import { optionSize } from 'src/utils/data/js/options'
import useCustomTranslation from 'src/utils/translation'
const { Panel } = Collapse

interface CollpaseCheckboxProps {
  onChangeCheckbox(value: any): any
  value: any
}

const CollpaseCheckbox: React.FunctionComponent<CollpaseCheckboxProps> = (props: any) => {
  const { value, onChangeCheckbox } = props
  const { t } = useCustomTranslation()

  return (
    <div className='collapse'>
      <Collapse
        expandIconPosition='right'
        className='collapse--container'
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={t('filter.fake_cloth_size')} key='1' className='collapse--checkbox-container'>
          {optionSize.map((item: any, itemKey: number) => (
            <Checkbox
              checked={value.includes(item.value)}
              key={itemKey}
              onChange={() => onChangeCheckbox(item.value)}
            >
              {t(item.label)}
            </Checkbox>
          ))}
        </Panel>
      </Collapse>
    </div>
  )
}

export default CollpaseCheckbox
