import { Dispatch } from 'redux'
import { GetAllGarmentsService, GetGarmentDetailService } from '../../services/garment'

import {
  FETCH_CURRENT_GARMENT,
  SET_QUERY_GARMENT_TYPE,
  FETCH_ALL_GARMENTS,
  FETCH_TYPE_GARMENT,
  FETCH_GARMENT_DETAIL,
} from './index'

export const FetchCurrentGarmentAction: Types.ActionFactory<Models.Garment> = (payload) => ({
  type: FETCH_CURRENT_GARMENT,
  payload,
})

export const SetQueryGarmentTypeAction: Types.ActionFactory<string> = (payload) => ({
  type: SET_QUERY_GARMENT_TYPE,
  payload,
})

export const FetchAllGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
  type: FETCH_ALL_GARMENTS,
  payload,
})

export const FetchTypeGarmentAction: Types.ActionFactory<string> = (payload) => ({
  type: FETCH_TYPE_GARMENT,
  payload,
})

export const FetchGarmentDetailAction: Types.ActionFactory<API.Resp.GetGarmentDetail> = (payload) => ({
  type: FETCH_GARMENT_DETAIL,
  payload,
})

export const GetAllGarmentsCall = (type: string) => async (dispatch: Dispatch<Types.Action>, getState: () => State.Root) => {
  const res: any = await GetAllGarmentsService({ type } as any)
  if ([200, 201, 204].includes(res?.status)) {
    dispatch(FetchTypeGarmentAction(type))
    dispatch(FetchAllGarmentAction(res.data))

    const look = getState().look?.current
    if (look && look[type.toLowerCase()]) {
      dispatch(FetchCurrentGarmentAction(look[type.toLowerCase()]))
    }
    else {
      dispatch(FetchCurrentGarmentAction(null))
    }
    
    return true
  }
  return false
}

export const GetAllGarmentsFromQueryCall = (type: string) => async (dispatch: Dispatch<Types.Action>) => {
  dispatch(SetQueryGarmentTypeAction(type))
  // @ts-ignore
  dispatch(GetAllGarmentsCall(type))
}

export const GetGarmentDetailCall = (id: string) => async (dispatch: Dispatch<Types.Action>) => {
  const res: any = await GetGarmentDetailService({ id })
  if ([200, 201, 204].includes(res.status)) {
    dispatch(FetchGarmentDetailAction(res.data))
    return true
  }
  return false
}
