import { Dispatch } from 'redux'
import { GetAllGarmentsService } from '../../services/garment'
import { GetAllGarmentsCall } from './garment'

import {
  FETCH_ALL_TOP_GARMENTS,
  FETCH_ALL_BOTTOM_GARMENTS,
  FETCH_ALL_DRESS_GARMENTS,
  FETCH_ALL_OUTERWEAR_GARMENTS,
} from './index'

export const FetchAllTopGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
  type: FETCH_ALL_TOP_GARMENTS,
  payload,
})

export const FetchAllBottomGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
  type: FETCH_ALL_BOTTOM_GARMENTS,
  payload,
})

export const FetchAllDressGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
  type: FETCH_ALL_DRESS_GARMENTS,
  payload,
})

export const FetchAllOuterwearGarmentAction: Types.ActionFactory<API.Resp.GetGarments> = (payload) => ({
  type: FETCH_ALL_OUTERWEAR_GARMENTS,
  payload,
})

export const GetAllSwipeGarmentsCall = (type: string) => async (dispatch: Dispatch<Types.Action>) => {
  const GetAllTopGarmentsCall = async () => {
    const res: any = await GetAllGarmentsService({ type: 'TOP' } as any)
    if ([200, 201, 204].includes(res?.status)) {
      dispatch(FetchAllTopGarmentAction(res.data))
    }
  }

  const GetAllBottomGarmentsCall = async () => {
    const res: any = await GetAllGarmentsService({ type: 'BOTTOM' } as any)
    if ([200, 201, 204].includes(res?.status)) {
      dispatch(FetchAllBottomGarmentAction(res.data))
    }
  }

  const GetAllDressGarmentsCall = async () => {
    const res: any = await GetAllGarmentsService({ type: 'DRESS' } as any)
    if ([200, 201, 204].includes(res?.status)) {
      dispatch(FetchAllDressGarmentAction(res.data))
    }
  }

  const GetAllOuterwearGarmentsCall = async () => {
    const res: any = await GetAllGarmentsService({ type: 'OUTERWEAR' } as any)
    if ([200, 201, 204].includes(res?.status)) {
      dispatch(FetchAllOuterwearGarmentAction(res.data))
    }
  }

  // @ts-ignore
  dispatch(GetAllGarmentsCall(type))
  switch (type) {
    case 'TOP':
    case 'BOTTOM':
      GetAllTopGarmentsCall()
      GetAllBottomGarmentsCall()
      break
    case 'DRESS':
      GetAllDressGarmentsCall()
      break
    case 'OUTERWEAR':
      GetAllOuterwearGarmentsCall()
      break
  }
}
