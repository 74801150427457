import { FETCH_ALL_MODELS } from '../actions'

export const initialState: State.ModelProduct = {
  all: null,
}

const ModelReducers = (
  state: State.ModelProduct = initialState,
  action: Types.Action
): State.ModelProduct => {
  switch (action.type) {
    case FETCH_ALL_MODELS:
      return {
        ...state,
        all: action.payload,
      }
    default:
      return state
  }
}

export default ModelReducers
