import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Card from 'antd/lib/card'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import Image from 'antd/lib/image'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { SetLookIndexAction } from '../../store/actions/look'
import { resizeImage } from 'src/utils/image'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking';

import { HeartOutlined, HeartFilled, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'

interface CardModelProps {
  look: Models.Look
  lookLoading: boolean
  imageUrls: string[]
  index: number
  ratio: number
  favorites: Models.Look[]
  addOnFavorites(value: Models.Look): void
  removeFromFavorites(item: Models.Look): void
}

const checkIsFavorites = (favoritesList: Models.Look[], current: Models.Look) => {
  const res = favoritesList.find((item: Models.Look) => item.look_id === current.look_id)
  return res
}

const CardModel: React.FunctionComponent<CardModelProps> = (props) => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const { look, lookLoading, imageUrls, index, favorites, addOnFavorites, removeFromFavorites, ratio } = props
  const isFavorites = !checkIsFavorites(favorites, look)
  const [visible, setVisible] = useState(false)

  const domain = getQueryValue('domain')
  const withInfo = !domain || domain !== 'marinehenrion.com'

  const handleCardClick = () => {
    trackEvent('Outfit Clicked', [look, {outfit_index_selected: index}], 'Outfit')
  }

  const handleDotClick = (e, key) => {
    e.stopPropagation()
    trackEvent('Carousel Selected', [look, {outfit_index_selected: index, carousel_type: 'dot'}], 'Outfit')
    dispatch(SetLookIndexAction(key))
  }

  const handleAddToFavorite = (e) => {
    e.stopPropagation()
    trackEvent('Outfit Saved to favorite', [look, {outfit_index_selected: index}], 'Outfit')
    addOnFavorites(look)
  }

  const handleRemoveFromFavorite = (e) => {
    e.stopPropagation()
    trackEvent('Outfit Removed from favorite', [look, {outfit_index_selected: index}], 'Outfit')
    removeFromFavorites(look)
  }

  const handleZoomClick = (e) => {
    e.stopPropagation()
    trackEvent('Zoom Opened', [look, {outfit_index_selected: index}], 'Outfit')
    setVisible(true)
  }

  return (
    <Card
      className='card-model card-model--container override_img_container'
      hoverable
      onClick={handleCardClick}
      cover={
        <div className='card-model--image-container'>
          <div className='card-model--image'>
            <ImageSmooth className='card-model--background' src={imageUrls ? resizeImage(imageUrls[index], {width: 800}) : 'none'} ratio={ratio} lazyload={false} loader={true} loading={lookLoading} />
          </div>
        </div>
      }
    >
      {look && (
        <>
          {imageUrls && imageUrls.length > 1 && (
            <div className='card-model card-model--bottom-left'>
              <Row className='card-model card-model--content'>
                <Row className='image--dot-container'>
                  {imageUrls.map((item: any, key: number) => (
                    <div
                      key={key}
                      className={`image--dot ${key == index ? 'image--dot-active' : ''}`}
                      onClick={(e) => handleDotClick(e, key)}
                    />
                  ))}
                </Row>
              </Row>
            </div>
          )}
          <div className='card-model--top'>
            {isFavorites ? (
              <Button
                onClick={handleAddToFavorite}
                icon={<HeartOutlined />}
                type='link'
                className='button'
              />
            ) : (
              <Button
                icon={<HeartFilled />}
                type='link'
                className='button'
                onClick={handleRemoveFromFavorite}
              />
            )}
            {withInfo && (
              <Tooltip
                trigger={['click', 'hover']}
                placement='bottomRight'
                overlayClassName='card-model card-model--tooltip'
                title={t('look.info')}
              >
                <Button icon={<InfoCircleOutlined />} type='link' className='button'></Button>
              </Tooltip>
            )}
          </div>
          <div className='card-model--bottom'>
            <Button
              icon={<SearchOutlined />}
              type='link'
              className='button'
              onClick={handleZoomClick}
            ></Button>
            {visible && (
              <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                  <Image src={resizeImage(imageUrls[index], {width: 800})} />
                  {imageUrls && imageUrls.map((item: any, key: number) => (
                    key !== index && (
                      <Image key={key} src={resizeImage(item, {width: 800})} />
                    )
                  ))}
                </Image.PreviewGroup>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  )
}

export default CardModel
