import ApiInstance from './index'

import { getQueryLocale } from 'src/utils/query';

const MODELS_ENDPOINT = `/models`

// GET ALL GARMENTS

const GetAllModelsService = async (): Promise<API.Resp.GetModelsProduct> => {
  try {
    return await ApiInstance.get(`${MODELS_ENDPOINT}`, { params: { locale: getQueryLocale() }})
  } catch (error) {
    return error.response
  }
}

export { GetAllModelsService }
