import { Dispatch } from 'redux'
import { GetCompanyProfileService } from '../../services/profile'

import { FETCH_COMPANY_PROFILE } from './index'

export const FetchCompanyProfileAction: Types.ActionFactory<State.Profile> = (payload) => ({
  type: FETCH_COMPANY_PROFILE,
  payload,
})

export const GetCompanyProfileCall = () => async (dispatch: Dispatch<Types.Action>) => {
  const res: any = await GetCompanyProfileService()
  if ([200, 201, 204].includes(res?.status)) {
    dispatch(FetchCompanyProfileAction(res.data))
    return true
  }
  return false
}
