import ApiInstance from './index'

import { getQueryLocale } from 'src/utils/query';

const PROFILES_ENDPOINT = `/config`

// GET A PROFILE
const GetCompanyProfileService = async (): Promise<API.Resp.GetCompanyProfile> => {
  try {
    return await ApiInstance.get(`${PROFILES_ENDPOINT}`, { params: { locale: getQueryLocale() }})
  } catch (error) {
    return error.response
  }
}

export { GetCompanyProfileService }
