import {
  FETCH_CURRENT_GARMENT,
  SET_QUERY_GARMENT_TYPE,
  FETCH_ALL_GARMENTS,
  FETCH_TYPE_GARMENT,
  FETCH_GARMENT_DETAIL,
} from '../actions'

export const initialState: State.Garment = {
  queryType: null,
  type: null,
  current: null,
  all: null,
  detail: null,
}

const GarmentReducers = (
  state: State.Garment = initialState,
  action: Types.Action
): State.Garment => {
  switch (action.type) {
    case FETCH_CURRENT_GARMENT:
      return {
        ...state,
        current: action.payload,
      }
    case SET_QUERY_GARMENT_TYPE:
      return {
        ...state,
        queryType: action.payload,
      }
    case FETCH_ALL_GARMENTS:
      return {
        ...state,
        all: action.payload,
      }
    case FETCH_TYPE_GARMENT:
      return {
        ...state,
        type: action.payload,
        queryType: action.payload,
      }
    case FETCH_GARMENT_DETAIL:
      return {
        ...state,
        detail: action.payload,
      }
    default:
      return state
  }
}

export default GarmentReducers
