import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCustomTranslation from 'src/utils/translation'
import Switch from 'antd/lib/switch'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { GetLookCall, SetLookIndexAction } from '../../store/actions/look'
import { AddFavoritesOutfitAction, RemoveFavoritesOutfitAction } from 'src/store/actions/favorites'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking';

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Loader from 'src/components/Loader'
import CardModel from 'src/components/card/CardModel'
import Thumbnail from 'src/components/image/Thumbnail'

const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img: HTMLImageElement = new Image()
    img.onload = function() {
      img.onload = null
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      img.onerror = img.onabort = null
      reject('Cannot load img : ' + src)
    }
    img.setAttribute('src', src);
    (window as any).usePreloadImagesData[src] = img
  })
}

interface LookContainerProps {
  hideThumbnails?: boolean
  swipeStyle?: boolean
  scrollToRef?: any
  contentMaxWidth?: number
}

const LookContainer: React.FunctionComponent<LookContainerProps> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const look = useSelector((state: State.Root) => state.look?.current)
  const lookLoading = useSelector((state: State.Root) => state.look?.loading)
  const lookIndex = useSelector((state: State.Root) => state.look?.index)
  const outerwear = useSelector((state: State.Root) => state.look?.outerwear)
  const favorites = useSelector((state: State.Root) => state.favorites).outfits
  const company = useSelector((state: State.Root) => state.profile?.company)
  const [imageUrls, setImageUrls] = useState([])
  const [tuckedIn, setTuckedIn] = useState(false)
  const [outerwearOn, setOuterwearOn] = useState(look && !!look.outerwear)
  const { hideThumbnails, swipeStyle, scrollToRef, contentMaxWidth } = props
  const lookTimestamp: any = useRef()

  const imageSize = 800

  useEffect(() => {
    const loadImages = async (newImageUrls) => {
      const localTimestamp = Date.now();
      // eslint-disable-next-line
      (window as any).usePreloadImagesData = {}
      const imagesPromiseList: Promise<any>[] = []
      for (const i of newImageUrls) {
        imagesPromiseList.push(preloadImage(resizeImage(i, {width: imageSize})))
      }
      await Promise.all(imagesPromiseList)
      if (!lookTimestamp.current || localTimestamp >= lookTimestamp.current){
        lookTimestamp.current = localTimestamp
        setImageUrls(newImageUrls)
      }
    }
    if (look && look.image_urls && imageUrls != look.image_urls) {
      loadImages(look.image_urls)
    }
    if (look) {
      setOuterwearOn(!!look.outerwear)
    }
    // eslint-disable-next-line
  }, [look])

  const lookImgRatio = 100 / (company?.look_image_ratio || 0.66)

  if (!look) {
    return (
      <Row className='look look--container' style={{ height: '100%' }}>
        <Loader />
      </Row>
    )
  }

  const addOnFavorites = (current: Models.Look) => {
    dispatch(AddFavoritesOutfitAction([...favorites, current] as any))
  }

  const removeOnFavorites = (current: Models.Look) => {
    const dataList: any = [...favorites]
    const currentIndex = dataList.findIndex((item) => item.look_id === current.look_id)
    if (currentIndex >= 0) dataList.splice(currentIndex, 1)
    dispatch(RemoveFavoritesOutfitAction(dataList))
  }

  const handleChangeTuck = (e) => {
    trackEvent('Tuck in Changed', [look, {tuck_value: e ? 'tuck' : 'untuck'}], 'Outfit')
    setTuckedIn(e)
    dispatch(GetLookCall({mode: e ? 'tuck' : 'untuck'}))
  }

  const handleChangeCoat = (e) => {
    trackEvent('Coat on Changed', [look, {coat_value: e ? 'on' : 'off'}], 'Outfit')
    setOuterwearOn(e)
    dispatch(GetLookCall({outerwear_garment_id: e ? ((outerwear && outerwear.garment_id) || '__RANDOM__') : '__REMOVE__'}))
  }

  const handleChangeModelClick = () => {
    trackEvent('Choose model Clicked', look, 'Outfit')
    history.push('/model'+window.location.search, swipeStyle && {from: 'swipe'})
  }

  const handleDotClick = (index) => {
    trackEvent('Carousel Selected', [look, {outfit_index_selected: index, carousel_type: 'dot'}], 'Outfit')
    dispatch(SetLookIndexAction(index))
  }

  const handleSeeLookDetailClick = () => {
    trackEvent('Outfit Detailed', look, 'Outfit')
    if (swipeStyle && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    else {
      history.push('/cart'+window.location.search, {from: 'look'})
    }
  }

  const showThumbnails = look && imageUrls.length > 1 && !hideThumbnails
  const lookImageUrlsLoading = look && imageUrls != look.image_urls

  return (
    <Row className={'look look--container' + (swipeStyle ? ' look--container-swipe' : '')}>
      <Row className='look--head'>
        <div className='look--switch--row'>
          <Col className='look--switch'>
            <span>{t('look.tuck_in')}</span>
            <Switch size='small' checked={tuckedIn} onChange={handleChangeTuck} disabled={!look || !company || !company.enable_tuck || !!look.dress} />
          </Col>
          <Col className='look--switch'>
            <span>{t('look.coat_in')}</span>
            <Switch size='small' checked={outerwearOn} onChange={handleChangeCoat} disabled={!look || !company || company.garment_types.indexOf('OUTERWEAR') === -1} />
          </Col>
        </div>
        <Col>
          <Button onClick={handleChangeModelClick} ghost className='button card--light'>
            {t('look.change_model')}
          </Button>
        </Col>
      </Row>
      <Row className={swipeStyle ? 'look--swipe-content': 'look--content'} style={contentMaxWidth && {maxWidth: contentMaxWidth}} gutter={[16, 0]}>
        {
          showThumbnails && 
          <Col span={4} className='look--thumbnail look--gutter'>
            <Row className='image image--col'>
              {imageUrls.map((item: string, key: number) => (
                <div
                  key={key}
                  className='image--minimize image--white override_img_container'
                  onClick={() => handleDotClick(key)}
                >
                  <ImageSmooth src={resizeImage(item, {width: imageSize})} ratio={lookImgRatio} lazyload={false} />
                </div>
              ))}
            </Row>
          </Col>
        }
        
        <Col span={showThumbnails ? 18 : 24} className='look--gutter'>
          <CardModel
            look={look}
            lookLoading={lookLoading !== 0 || lookImageUrlsLoading}
            imageUrls={imageUrls}
            index={lookIndex}
            ratio={lookImgRatio}
            favorites={favorites}
            addOnFavorites={addOnFavorites}
            removeFromFavorites={removeOnFavorites}
          />
          <Button className={'button look--button' + (swipeStyle ? ' look--button-swipe' : '')} type='primary' onClick={handleSeeLookDetailClick}>
            {t('look.see_look_detail')}
          </Button>
          {!hideThumbnails && <Thumbnail look={look} />}
        </Col>
      </Row>
    </Row>
  )
}

export default LookContainer
