import React from 'react'
import { useHistory } from 'react-router-dom'

import Breadcrumb from 'antd/lib/breadcrumb'
import Button from 'antd/lib/button'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import useCustomTranslation from 'src/utils/translation'

import { trackEvent, getPageName } from 'src/utils/tracking';

interface HeaderBreadcrumbProps {
  routes: { path: string; breadcrumbName: string }[]
}

const HeaderBreadcrumb: React.FunctionComponent<HeaderBreadcrumbProps> = (props) => {
  const history = useHistory()
  const { t } = useCustomTranslation()
  const { routes } = props

  const handleClick = (e, path) => {
    e.preventDefault()
    trackEvent('Breadcrumb Clicked', {breadcrumb_target: getPageName(path.replace(/\?.+/, ''))}, 'Menu')
    history.push(path)
  }

  const handleBackClick = () => {
    trackEvent('Return Clicked', {}, 'Menu')
    history.goBack()
  }

  return (
    <div className='breadcrumb breadcrumb--row'>
      <Button
        onClick={handleBackClick}
        icon={<LeftOutlined />}
        className='breadcrumb breadcrumb--back'
      >
        {t('breadcrumb.back')}
      </Button>

      <div className='breadcrumb--separator' />

      <Breadcrumb separator={<RightOutlined />} className='breadcrumb breadcrumb--container'>
        {routes.map((item: { path: string; breadcrumbName: string }, key: number) => (
          <Breadcrumb.Item
            key={key}
            className='breadcrumb--item'
            href={`${item.path}`}
            onClick={(e) => handleClick(e, item.path)}
          >
            {item.breadcrumbName}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}

export default HeaderBreadcrumb
