export const optionSize = [
  { label: 'options.small', value: 'small' },
  { label: 'options.medium', value: 'medium' },
  { label: 'options.large', value: 'large' },
  { label: 'options.xlarge', value: 'xlarge' },
  { label: 'options.xxlarge', value: 'xxlarge' },
]

export const optionsColor = [
  { label: 'options.blue', value: 'blue' },
  { label: 'options.red', value: 'red' },
  { label: 'options.purple', value: 'purple' },
  { label: 'options.green', value: 'green' },
  { label: 'options.pink', value: 'pink' },
]

export const optionsSkinColor = [
  { label: 'options.clear', value: 'clear' },
  { label: 'options.medium', value: 'medium' },
  { label: 'options.dark', value: 'dark' },
]

export const optionsType = [
  { label: 'options.crop', value: 'crop' },
  { label: 'options.top', value: 'top' },
  { label: 'options.bottom', value: 'bottom' },
  { label: 'options.dress', value: 'dress' },
]

export const optionsFilter = [
  { label: 'options.most', value: 'most' },
  { label: 'options.less', value: 'less' },
  { label: 'options.recommended', value: 'recommended' },
]

export const optionsGender = [
  { label: 'options.man', value: 'man' },
  { label: 'options.female', value: 'female' },
  { label: 'options.others', value: 'others' },
]
