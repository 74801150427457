// @ts-nocheck
import React, { useEffect } from 'react'
import useCustomTranslation from 'src/utils/translation'

import CarouselModel from 'src/components/carousel/carouselModel'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { trackPage } from 'src/utils/tracking';

const ModelPage: React.FunctionComponent = () => {
  const { t } = useCustomTranslation()

  useEffect(() => {
    trackPage()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='model model--container'>
      <div className='layout--title'>
        <h1 className='title title--h1 title'>{t('model.title')}</h1>
      </div>
      <div className='carousel carousel--size'>
        <CarouselModel />
      </div>
    </div>
  )
}

export default ModelPage
