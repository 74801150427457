import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import useCustomTranslation from 'src/utils/translation'

import { HeartOutlined, HeartFilled } from '@ant-design/icons'

import Layout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import LookTracking from '../containers/look/LookTracking'

import { trackEvent, trackPage } from 'src/utils/tracking';
import CartPage from './cart'
import LookContainer from 'src/containers/look/Look'
import { GetAllSwipeGarmentsCall } from 'src/store/actions/swipe'
import Loader from 'src/components/Loader'
import CarouselGarment from 'src/components/carousel/carouselGarment'
import { GetLookCall } from 'src/store/actions/look'

const { Header, Content } = Layout

const SwipePage: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { t } = useCustomTranslation()
  const favorites = useSelector((state: State.Root) => state.favorites)
  const garmentType = useSelector((state: State.Root) => state.garment?.type)
  const company = useSelector((state: State.Root) => state.profile?.company)
  const swipe = useSelector((state: State.Root) => state.swipe)
  const look = useSelector((state: State.Root) => state.look?.current)
  const lookOuterwear = useSelector((state: State.Root) => state.look?.outerwear)
  const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
  const swipeTopRatio = useSelector((state: State.Root) => state.profile?.company?.swipe_top_ratio)
  const swipeBottomRatio = useSelector((state: State.Root) => state.profile?.company?.swipe_bottom_ratio)
  const swipeDressRatio = useSelector((state: State.Root) => state.profile?.company?.swipe_dress_ratio)
  const cartRef = useRef(null)
  const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)
  const [current, setCurrent] = useState(garmentType || 'TOP')

  useEffect(() => {
    trackPage()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let interval = null
    function handleResize() {
      const content = document.getElementsByClassName('layout--swipe-content')[0]
      if (content) {
        clearInterval(interval)
        const newWidth = (content.clientHeight - 136) * lookRatio
        const newHeight = (content.clientWidth - 80) / lookRatio
        const newMaxWidth = newWidth <= (content.clientWidth - 80) ? newWidth : newHeight * lookRatio
        setContentMaxWidth(newMaxWidth)
      }
    }
    interval = setInterval(() => {
      handleResize()
    }, 100)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (company && company.garment_types && company.garment_types.length) {
      if (company.garment_types.indexOf(current) === -1) {
        dispatch(GetAllSwipeGarmentsCall(company.garment_types[0]))
      }
      else {
        dispatch(GetAllSwipeGarmentsCall(current))
      }
    }
    // eslint-disable-next-line
  }, [company])

  useEffect(() => {
    if (garmentType && garmentType != current) {
      setCurrent(garmentType)
    }
    if (look) {
      switch (garmentType) {
        case 'TOP':
        case 'BOTTOM':
          if ((!look.top || !look.bottom) && swipe?.allTop?.items.length && swipe?.allBottom?.items.length) {
            dispatch(GetLookCall({top_garment_id: swipe.allTop.items[0].garment_id, bottom_garment_id: swipe.allBottom.items[0].garment_id}));
          }
          break
        case 'DRESS':
          if (!look.dress && swipe?.allDress?.items.length) {
            dispatch(GetLookCall({dress_garment_id: swipe.allDress.items[0].garment_id}));
          }
          break
        case 'OUTERWEAR':
          if (!look.outerwear && swipe?.allOuterwear?.items.length) {
            dispatch(GetLookCall({outerwear_garment_id: swipe.allOuterwear.items[0].garment_id}));
          }
          break
      }
    }
    // eslint-disable-next-line
  }, [garmentType, swipe])

  const handleFavoritesClick = (e) => {
    e.preventDefault()
    trackEvent('Favorite Clicked', {favorite_count: favorites?.outfits?.length + favorites?.items?.length}, 'Menu')
    history.push('/favorites' + window.location.search)
  }

  const handleTitleClick = (e) => {
    e.preventDefault()
    trackEvent('Home Clicked', {}, 'Menu')
    if (location.pathname !== '/') {
      history.push('/' + window.location.search)
    }
  }

  const handleTypeClick = (value) => {
    trackEvent('Type Clicked', {catalog_type: value}, 'Menu')
    setCurrent(value)
    if (value != garmentType) {
      dispatch(GetAllSwipeGarmentsCall(value))
    }
  }

  const handleChangeViewClick = () => {
    history.push('/' + window.location.search)
  }

  const garmentTypes = company && company.garment_types || [] 
  const MenuOption = (garmentTypes.indexOf('DRESS') !== -1 || garmentTypes.indexOf('DRESS') !== -1) ? [
    { label: t('layout.top'), value: 'TOP' },
    { label: t('layout.bottom'), value: 'BOTTOM' },
    { label: t('layout.dress'), value: 'DRESS' },
    { label: t('layout.outerwear'), value: 'OUTERWEAR' },
    { label: t('layout.other'), value: 'OTHER' },
  ] : []

  return (
    <>
      <Layout className='layout layout--container'>
        <Row className='layout--header-row'>
          <Col xs={0} sm={0} md={12} xl={8}>
            <Header className='pageheader pageheader--paper-left'>
              <h1 className='title title--h1 title' onClick={handleTitleClick}>{t('layout.see_the_look')}</h1>
              <div className='pageheader--swipe'>
                <div
                  onClick={handleChangeViewClick}
                  className='button--underlined'
                >
                  {t('layout.model_view')}
                </div>
                <Button
                  icon={
                    favorites.outfits.length || favorites.items.length ? (
                      <HeartFilled />
                    ) : (
                      <HeartOutlined />
                    )
                  }
                  type='primary'
                  ghost
                  onClick={handleFavoritesClick}
                  className='button--outlined button--space-r button--mobile'
                >
                  {t('layout.favorites')}
                </Button>
              </div>
            </Header>
          </Col>
          <Col xs={24} sm={24} md={12} xl={16} >
            <Header className='pageheader pageheader--paper swipe--center'>
                <Button
                  onClick={handleChangeViewClick}
                  className='button--outlined button--space-r button--mobile'
                >
                  {t('layout.model_view')}
                </Button>
                <Button
                  icon={
                    favorites.outfits.length || favorites.items.length ? (
                      <HeartFilled />
                    ) : (
                      <HeartOutlined />
                    )
                  }
                  type='primary'
                  ghost
                  onClick={handleFavoritesClick}
                  className='button--outlined button--space-r'
                >
                  {t('layout.favorites')}
                </Button>
            </Header>

            <Header className={'pageheader pageheader--paper swipe--right'}>
                <>
                  {MenuOption.length > 0 && (
                    <Menu
                      onClick={(e) => handleTypeClick(e.key)}
                      selectedKeys={[current]}
                      mode='horizontal'
                      className='menu menu--container'
                    >
                      {MenuOption.map(
                        (item: { label: string; value: string }) =>
                          garmentTypes.indexOf(item.value) !== -1 && (
                            <Menu.Item className='menu--item' key={item.value}>
                              {item.label}
                            </Menu.Item>
                          )
                      )}
                    </Menu>
                  )}
                  <Col className='col col--flexcenter'>
                    <Button
                      icon={
                        favorites.outfits.length || favorites.items.length ? (
                          <HeartFilled />
                        ) : (
                          <HeartOutlined />
                        )
                      }
                      type='primary'
                      ghost
                      onClick={handleFavoritesClick}
                      className='button--outlined button--space-r button--desktop'
                    >
                      {t('layout.favorites')}
                    </Button>
                  </Col>
                </>
            </Header>
          </Col>
        </Row>
        <Row className='layout--container' style={{paddingTop: '4rem'}}>
          <Col xs={0} sm={0} md={12} xl={8} className='layout--side-left'>
            <Content className={'layout--left-content layout--with-header'}>
              <CartPage swipeStyle/>
            </Content>
            <LookTracking />
          </Col>
          <Col xs={24} sm={24} md={12} xl={16} className='layout--side-right'>
            {(!look ||
              (['TOP', 'BOTTOM'].indexOf(garmentType) !== -1 && (!swipe.allTop || !swipe.allBottom || !look.top || !look.bottom)) ||
              (garmentType === 'DRESS' && (!swipe.allDress || !look.dress)) ||
              (garmentType === 'OUTERWEAR' && (!swipe.allOuterwear || (!look.outerwear && !lookOuterwear)))) ? (
                <Loader />
              ) : (
              <Content className='layout--swipe-content layout--with-header'>
                {
                  contentMaxWidth &&
                  <>
                    {['TOP', 'BOTTOM'].indexOf(garmentType) !== -1 && (
                      <>
                        <div className='swipe--carousel swipe--carousel-top' style={{top: (contentMaxWidth - 16) / lookRatio * (swipeTopRatio?.top || 0.25) + 94}}>
                          <CarouselGarment type="top" data={swipe.allTop.items} cardHeight={(contentMaxWidth - 16) / lookRatio * (swipeTopRatio?.height || 0.25)}/>
                        </div>
                        <div className='swipe--carousel swipe--carousel-bottom' style={{top: (contentMaxWidth - 16) / lookRatio * (swipeBottomRatio?.top || 0.5) + 94}}>
                          <CarouselGarment type="bottom" data={swipe.allBottom.items} cardHeight={(contentMaxWidth - 16) / lookRatio * (swipeBottomRatio?.height || 0.3)}/>
                        </div>
                      </>
                    )}
                    {garmentType === 'DRESS' && (
                      <div className='swipe--carousel swipe--carousel-top' style={{top: (contentMaxWidth - 16) / lookRatio * (swipeDressRatio?.top || 0.25) + 94}}>
                        <CarouselGarment type="dress" data={swipe.allDress.items} cardHeight={(contentMaxWidth - 16) / lookRatio * (swipeDressRatio?.height || 0.5)}/>
                      </div>
                    )}
                    {garmentType === 'OUTERWEAR' && (
                      <div className='swipe--carousel swipe--carousel-top' style={{top: (contentMaxWidth - 16) / lookRatio * (swipeTopRatio?.top || 0.25) + 94}}>
                        <CarouselGarment type="outerwear" data={swipe.allOuterwear.items} cardHeight={(contentMaxWidth - 16) / lookRatio * (swipeTopRatio?.height || 0.25)}/>
                      </div>
                    )}
                    <LookContainer hideThumbnails swipeStyle scrollToRef={cartRef} contentMaxWidth={contentMaxWidth}/>
                    <div className='layout--headroom' ref={cartRef}>
                      <CartPage swipeStyle/>
                    </div>
                  </>
                }
              </Content>
            )}
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default SwipePage
