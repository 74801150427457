// @ts-nocheck
import React, { useEffect } from 'react'

import Catalog from '../containers/catalog/Catalog'
import Filters from '../containers/filters/filters'

import { trackPage } from 'src/utils/tracking';
import { useSelector } from 'react-redux';

const HomePage: React.FunctionComponent = () => {
  const garmentFilters = useSelector((state: State.Root) => state.profile?.company.garment_filters)

  useEffect(() => {
    trackPage()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {garmentFilters === true && <Filters />}
      <Catalog />
    </>
  )
}

export default HomePage
