import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Sticky from 'react-sticky-el'

import Tabs from 'antd/lib/tabs'
const { TabPane } = Tabs

import { HeartOutlined, HeartFilled, LeftOutlined } from '@ant-design/icons'

import Layout from 'antd/lib/layout'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Menu from 'antd/lib/menu'
import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import LookContainer from '../look/Look'
import LookTracking from '../look/LookTracking'
import { SettingOutlined } from '@ant-design/icons'

import { GetAllGarmentsCall } from '../../store/actions/garment'
import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'

import { handleSidebarAction } from 'src/store/actions/sidebar'
import { ReactComponent as FiltersIcon } from '../../static/icons/filter.svg'
import FiltersModel from '../filters/FiltersModel'
import FilterResponsive from '../filters/FilterResponsive'
import MenuTab from 'src/components/menu/MenuTab'
import HeaderBreadcrumb from 'src/components/breadcrumb/headerBreadcrumb'
import { breadcrumbRoutes } from 'src/utils/breadcrumb'
import { getQueryValue } from 'src/utils/query';
import { trackEvent } from 'src/utils/tracking';
import eventBus from 'src/utils/eventBus';
import useCustomTranslation from 'src/utils/translation'

const { Header, Content } = Layout

interface LayoutProps {
  children: React.ReactNode
}

const LayoutContainer: React.FunctionComponent = (props: LayoutProps) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const sidebar = useSelector((state: State.Root) => state.sidebar)
  const company = useSelector((state: State.Root) => state.profile?.company)
  const garmentType = useSelector((state: State.Root) => state.garment?.type)
  const favorites = useSelector((state: State.Root) => state.favorites)

  const [current, setCurrent] = useState(garmentType || 'TOP')

  useEffect(() => {
    const pinHeaderHandler = () => {
      if (window.innerWidth < 768) {
        const scrollElement = document.getElementById("layoutScrollableContent")
        if(scrollElement) {
          scrollElement.scrollTo(0, 0)
        }
      }
    }
    eventBus.on('pinHeader', pinHeaderHandler)
    return () => eventBus.remove('pinHeader', pinHeaderHandler);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (company && company.garment_types && company.garment_types.length) {
      if (company.garment_types.indexOf(current) === -1) {
        dispatch(GetAllGarmentsCall(company.garment_types[0]))
      }
    }
    // eslint-disable-next-line
  }, [company])

  useEffect(() => {
    if (garmentType && garmentType != current) {
      setCurrent(garmentType)
    }
    // eslint-disable-next-line
  }, [garmentType])

  const handleTypeClick = (value) => {
    trackEvent('Type Clicked', {catalog_type: value}, 'Menu')
    setCurrent(value)
    if (value != garmentType) {
      dispatch(GetAllGarmentsCall(value))
    }
  }

  const handleFavoritesClick = (e) => {
    e.preventDefault()
    trackEvent('Favorite Clicked', {favorite_count: favorites?.outfits?.length + favorites?.items?.length}, 'Menu')
    history.push('/favorites' + window.location.search)
  }

  const handleFavoritesList = (value: any) => {
    trackEvent('Favorite type Clicked', {favorite_type: value == '1' ? 'outfit' : 'item', favorite_count: value == '1' ? favorites?.outfits?.length : favorites?.items?.length}, 'Menu')
    dispatch(SwitchFavoritesContentAction(value === '1'))
  }

  const handleTitleClick = (e) => {
    e.preventDefault()
    trackEvent('Home Clicked', {}, 'Menu')
    if (location.pathname !== '/') {
      history.push('/' + window.location.search)
    }
  }

  const handleFilterClick = (e, path = null) => {
    e.preventDefault()
    if (path === '/model') {
      trackEvent('Filter Clicked', {filter_type: 'model'}, 'Menu')
    }
    else {
      trackEvent('Filter Clicked', {catalog_type: current, filter_type: 'item'}, 'Menu')
    }
    dispatch(handleSidebarAction(path))
  }

  const handleBackClick = () => {
    trackEvent('Return Clicked', {}, 'Menu')
    history.goBack()
  }

  const findTitleHeader = () => {
    switch (location.pathname) {
      case '/':
        if (getQueryValue('domain') == 'christmas.com') {
          return 'Christmas outfit creator'
        }
        return t('layout.see_the_look')
      case '/model':
        return t('layout.choose_model')
      case '/favorites':
        return t('layout.my_favorites')
      case '/cart':
        return t('layout.look_detail')
      case '/product':
        return t('layout.product_page')
      default:
        return t('layout.see_the_look')
    }
  }

  const findRoutesBreadcrumb = () => {
    switch (location.pathname) {
      case '/favorites':
        return breadcrumbRoutes(['/', '/favorites'], t, true)
      case '/model':
        return breadcrumbRoutes(['/', '/model'], t, true)
      case '/cart':
        const historyState = (history.location?.state as any)
        if (historyState && historyState.from == 'favorites') {
          return breadcrumbRoutes(['/', '/favorites', '/cart'], t, true)
        }
        return breadcrumbRoutes(['/', '/cart'], t, true)
      case '/product':
        return breadcrumbRoutes(['/', '/product'], t, true)
      default:
        return breadcrumbRoutes(['/', '/favorites'], t, true)
    }
  }

  const MenuOption = [
    { label: t('layout.top'), value: 'TOP' },
    { label: t('layout.bottom'), value: 'BOTTOM' },
    { label: t('layout.dress'), value: 'DRESS' },
    { label: t('layout.outerwear'), value: 'OUTERWEAR' },
    { label: t('layout.other'), value: 'OTHER' },
  ]

  const domain = getQueryValue('domain');
  const withGarmentFilters = company.garment_filters === true;
  const withModelFilters = company.model_filters === true;

  const handleChangeViewClick = () => {
    history.push('/swipe' + window.location.search)
  }

  return (
    <>
      <Layout className='layout layout--container'>
        <Row className='layout--header-row'>
          <Col xs={0} sm={0} md={12} xl={8}>
            <Header className='pageheader pageheader--paper-left'>
              {domain == 'christmas.com' ? (
                <h1 className='title title--h1 title--h1-christmas title' onClick={handleTitleClick}>{'Christmas outfit creator'}</h1>
              ) : (
                <h1 className='title title--h1 title' onClick={handleTitleClick}>{t('layout.see_the_look')}</h1>
              )}
              <div className='pageheader--swipe'>
                {company?.enable_swipe && (
                  <div
                    onClick={handleChangeViewClick}
                    className='button--underlined button--space-r'
                  >
                    {t('layout.swipe_view')}
                  </div>
                )}
                <Button
                  icon={
                    favorites.outfits.length || favorites.items.length ? (
                      <HeartFilled />
                    ) : (
                      <HeartOutlined />
                    )
                  }
                  type='primary'
                  ghost
                  onClick={handleFavoritesClick}
                  className='button--outlined button--space-r button--mobile'
                >
                  {t('layout.favorites')}
                </Button>
              </div>
            </Header>
          </Col>
          <Col xs={24} sm={24} md={12} xl={16} >
            <Header className='pageheader pageheader--paper pageheader--left'>
              {location.pathname !== '/' && (
                <Button
                  onClick={handleBackClick}
                  className='button button--text button--icon'
                  type='text'
                  icon={<LeftOutlined />}
                ></Button>
              )}
              {location.pathname !== '/' && (
                <h1 className='title title--h1 title'>{findTitleHeader()}</h1>
              )}
              {location.pathname === '/' && (
                <>
                  {company?.enable_swipe ? (
                    <Button
                      onClick={handleChangeViewClick}
                      className='button--outlined button--space-r button--mobile'
                    >
                      {t('layout.swipe_view')}
                    </Button>
                  ) : (
                    <h1 className='title title--h1 title'>{findTitleHeader()}</h1>
                  )}
                  <Button
                    icon={
                      favorites.outfits.length || favorites.items.length ? (
                        <HeartFilled />
                      ) : (
                        <HeartOutlined />
                      )
                    }
                    type='primary'
                    ghost
                    onClick={handleFavoritesClick}
                    className='button--outlined button--space-r'
                  >
                    {t('layout.favorites')}
                  </Button>
                </>
              )}
              {(location.pathname === '/model' && withModelFilters) ? (
                <FiltersIcon
                  className='tabs--icon-header'
                  onClick={(e) => handleFilterClick(e, '/model')}
                />
              ) : (
                location.pathname !== '/' && <div />
              )}
            </Header>

            {location.pathname === '/' && (
              <div className='filters--mobile'>
                <MenuTab withFilters={withGarmentFilters} />
              </div>
            )}
            <Header className={'pageheader pageheader--paper pageheader--right' + (location.pathname !== '/' ? ' pageheader--widh--breadcrumb' : '')}>
              {location.pathname !== '/' ? (
                <>
                  <HeaderBreadcrumb routes={findRoutesBreadcrumb()} />
                  {location.pathname === '/favorites' && (
                    <Tabs activeKey={favorites.isOutfit ? '1' : '2'} onTabClick={handleFavoritesList}>
                      <TabPane tab={`${t('favorite.saved_outfits')} (${favorites?.outfits?.length})`} key='1' />
                      <TabPane tab={`${t('favorite.saved_items')} (${favorites?.items?.length})`} key='2' />
                    </Tabs>
                  )}
                </>
              ) : (
                <>
                  <Menu
                    onClick={(e) => handleTypeClick(e.key)}
                    selectedKeys={[current]}
                    mode='horizontal'
                    className='menu menu--container'
                  >
                    {MenuOption &&
                      MenuOption.map(
                        (item: { label: string; value: string }) =>
                          company &&
                          company?.garment_types?.indexOf(item.value) !== -1 && (
                            <Menu.Item className='menu--item' key={item.value}>
                              {item.label}
                            </Menu.Item>
                          )
                      )}
                  </Menu>
                  <Col className='col col--flexcenter'>
                    <Button
                      icon={
                        favorites.outfits.length || favorites.items.length ? (
                          <HeartFilled />
                        ) : (
                          <HeartOutlined />
                        )
                      }
                      type='primary'
                      ghost
                      onClick={handleFavoritesClick}
                      className='button--outlined button--space-r button--desktop'
                    >
                      {t('layout.favorites')}
                    </Button>
                    {withGarmentFilters && (
                      <Button
                        onClick={(e) => handleFilterClick(e, '/catalog')}
                        icon={<SettingOutlined />}
                        type='link'
                        className='button button--mobile'
                      />
                    )}
                  </Col>
                </>
              )}
            </Header>
          </Col>
        </Row>
        <Row className='layout--container' style={{paddingTop: '4rem'}}>
          <Col xs={0} sm={0} md={12} xl={8} className='layout--side-left'>
            <Content className={'layout--left-content' + ((location.pathname !== '/model' || !withModelFilters) ? ' layout--with-header' : '')}>
              {(location.pathname === '/model' && withModelFilters) ? (
                <FiltersModel mobile={false} />
              ) : (
                <LookContainer />
              )}
            </Content>
            <LookTracking />
          </Col>
          <Col xs={24} sm={24} md={12} xl={16} className='layout--side-right'>
            {location.pathname === '/' ? (
              <Content id="layoutScrollableContent" className='layout--right-content layout--with-header'>
                {location.pathname === '/' && (
                  <div className='layout--headroom'>
                    <LookContainer />
                    <Sticky scrollElement=".layout--right-content">
                      <MenuTab withFilters={withGarmentFilters} />
                    </Sticky>
                  </div>
                )}
                {props?.children}
              </Content>
            ) : (
              <Content className='layout--right-content layout--with-pageheader'>
                {props?.children}
              </Content>
            )}
          </Col>
        </Row>
        <div className='drawer'>
          <Drawer placement='right' visible={sidebar.open} maskClosable={true}>
            {sidebar.page === '/catalog' && (
              <>
                <FilterResponsive />
                <Button
                  type='primary'
                  className='button button--outlined drawer drawer--button'
                  onClick={() => dispatch(handleSidebarAction(null))}
                >
                  {t('filter.apply')} (36)
                </Button>
              </>
            )}

            {sidebar.page === '/model' && (
              <>
                <FiltersModel mobile />
                <Button
                  type='primary'
                  className='button button--outlined drawer drawer--button'
                  onClick={() => dispatch(handleSidebarAction(null))}
                >
                  {t('filter.apply')} (36)
                </Button>
              </>
            )}
          </Drawer>
        </div>
      </Layout>
    </>
  )
}

export default LayoutContainer
