import ApiInstance from './index'

import { getQueryLocale } from 'src/utils/query';

const GARMENTS_ENDPOINT = `/garments`
const GARMENT_ENDPOINT = `/garment/`

// GET ALL GARMENTS

const GetAllGarmentsService = async (
  params: API.Req.GetGarments
): Promise<API.Resp.GetGarments> => {
  try {
    return await ApiInstance.get(`${GARMENTS_ENDPOINT}`, { params: Object.assign(params, { locale: getQueryLocale() }) })
  } catch (error) {
    return error.response
  }
}

const GetGarmentDetailService = async (
  params: API.Req.GetGarmentDetail
): Promise<API.Resp.GetGarmentDetail> => {
  try {
    return await ApiInstance.get(`${GARMENT_ENDPOINT}${params.id}`, { params: { locale: getQueryLocale() }})
  } catch (error) {
    return error.response
  }
}

export { GetAllGarmentsService, GetGarmentDetailService }
