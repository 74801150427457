import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Slider from 'react-slick'

import CarouselCard from 'src/components/card/cardCarousel'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { trackEvent } from 'src/utils/tracking';
import Loader from '../Loader'

const CarouselModel: React.FunctionComponent = () => {
  const { t } = useCustomTranslation()
  const look = useSelector((state: State.Root) => state.look?.current)
  const data = useSelector((state: State.Root) => state.model?.all?.items)
  const modelInfinite = useSelector((state: State.Root) => state.profile?.company?.model_infinite)
  const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)

  const initialSlide = !look ? 0 : data.findIndex(
    (model) => model.model_id === look.model.model_id
  )
  const [currentSlide, setCurrentSlide] = useState(initialSlide)

  if (!look) {
    return <Loader />
  }
  if (!data) {
    return <div className='model--empty'></div>
  }
  if (!data.length) {
    return <div className='model--empty'>{t('model.no_models')}</div>
  }

  const toFlat = [data]
  if (modelInfinite !== false) {
    for (let i = data.length; i <= 3; i += data.length) {
      toFlat.push(data)
    }
  }
  const augmentedData = toFlat.flat()

  const CarouselNextArrow = (arrowProps) => {
    const { className, onClick } = arrowProps

    const handleClick = (e) => {
      trackEvent('Swipe right', augmentedData[currentSlide], 'Model Choice')
      onClick(e)
    }

    return (
      <div className={`${className} carousel--arrow carousel--right`} onClick={handleClick}>
        <RightOutlined />
      </div>
    )
  }

  const CarouselPrevArrow = (arrowProps) => {
    const { className, onClick } = arrowProps

    const handleClick = (e) => {
      trackEvent('Swipe left', augmentedData[currentSlide], 'Model Choice')
      onClick(e)
    }

    return (
      <div className={`${className} carousel--arrow carousel--left`} onClick={handleClick}>
        <LeftOutlined />
      </div>
    )
  }

  let slider = null

  const settings = {
    initialSlide: initialSlide,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: Math.max(Math.min(augmentedData.length, 3), 1),
    swipeToSlide: true,
    focusOnSelect: true,
    touchThreshold: 15,
    slidesToScroll: 1,
    speed: 200,
    arrow: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    onInit: () => {
      // FIXME: la class slick-current n'est pas correctement geree quand on utilise initialSlide
      const interval = setInterval(() => {
        if (slider) {
          slider.slickGoTo(initialSlide)
          clearInterval(interval)
        }
      }, 100)
    },
    beforeChange: (current, next) => {
      setCurrentSlide(next)
    },
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: Math.max(Math.min(augmentedData.length, 2), 1),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: Math.max(Math.min(augmentedData.length, 3), 1),
          slidesToScroll: 1,
        },
      },
    ],
  }

  const imgRatio = 100 / (lookRatio || 0.66)

  return (
    <>
      <Slider {...settings} ref={(ref) => slider = ref} className='carousel carousel--container'>
        {augmentedData &&
          augmentedData.length > 0 &&
          augmentedData.map((item: Models.ModelProduct, itemKey: number) => (
            <CarouselCard key={itemKey} id={itemKey} current={currentSlide} model={item} ratio={imgRatio}/>
          ))}
      </Slider>
    </>
  )
}

export default CarouselModel
