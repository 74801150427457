import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import Card from 'antd/lib/card'
import Typography from 'antd/lib/typography'
import Button from 'antd/lib/button'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { HeartOutlined, HeartFilled, LoadingOutlined } from '@ant-design/icons'

import FilterSelect from 'src/components/select/filter'

import { FetchCurrentGarmentAction } from 'src/store/actions/garment'
import { GetLookCall } from 'src/store/actions/look'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking';

const { Title, Paragraph } = Typography

interface CardItemProps {
  data: Models.Garment
  favorites: Models.Garment[]
  addOnFavorites(value: Models.Garment): void
  removeFromFavorites(item: Models.Garment): void
  ratio: number
}

const checkIsFavorites = (favoritesList: Models.Garment[], current: Models.Garment) => {
  const res = favoritesList.find((item: Models.Garment) => item.garment_id === current.garment_id)
  return res
}

const CardItem: React.FunctionComponent<CardItemProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useCustomTranslation()
  const garment = useSelector((state: State.Root) => state.garment)
  const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
  const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)

  const [currentSize, setCurrentSize] = useState(null)
  const [changingSize, setChangingSize] = useState(false)
  const [addingToCart, setAddingToCart] = useState(false)

  const { data, favorites, addOnFavorites, removeFromFavorites, ratio } = props
  const isFavorites = !checkIsFavorites(favorites, data)
  const isSelected = garment?.current?.garment_id === data?.garment_id
  const optionSize = getGarmentOptionSizes(data)

  const imageUrl = useClipping && data.image_clipping_url ? data.image_clipping_url : data.image_url

  useEffect(() => {
    setChangingSize(false)
  }, [isSelected])

  const handleCardClick = () => {
    trackEvent('Item Selected', data, 'Catalog')
    dispatch(FetchCurrentGarmentAction(data as any))
    dispatch(GetLookCall({[data.garment_type]: data.garment_id}))
  }

  const handleSeeProduct = (e) => {
    e.stopPropagation()
    trackEvent('Item Detailed', data, 'Catalog')
    history.push('/product'+window.location.search)
  }

  const handleChooseSize = (e) => {
    e.stopPropagation()
    trackEvent('Choose size Clicked', data, 'Catalog')
    setChangingSize(true)
  }

  const handleSizeChange = (value) => {
    trackEvent('Size Selected', [data, {item_size_selected: value}], 'Catalog')
    setCurrentSize(value)
  }

  const handleAddToCart = (e) => {
    e.stopPropagation()
    trackEvent('Item Added to cart', [data, {item_size_selected: currentSize}], 'Catalog')
    setAddingToCart(true)
    addGarmentSizeToCart(data, currentSize, (success) => {
      if (!success) {
        console.error(`Timeout when adding to cart`)
      }
      setAddingToCart(false)
    })
  }

  const handleAddToFavorite = (e) => {
    e.stopPropagation()
    trackEvent('Item Saved to favorite', data, 'Catalog')
    addOnFavorites(data)
  }

  const handleRemoveFromFavorite = (e) => {
    e.stopPropagation()
    trackEvent('Item Removed from favorite', data, 'Catalog')
    removeFromFavorites(data)
  }

  return (
    <Card
      className='card card--container override_card_container override_card_catalog_container'
      hoverable
      onClick={handleCardClick}
      cover={
          <div className='card card--image-container'>
            <div className={'override_card_cover_catalog ' + (isSelected ? 'card--image card--blur' : 'card--image')}>
              <ImageSmooth className='card--background' src={resizeImage(imageUrl, {width: 800})} ratio={ratio} transition={false} />
            </div>
            {isSelected && (
              <div className='card--image-blur'>
                <div className='card--actions'>
                  <Button
                    onClick={handleSeeProduct}
                    ghost
                    className='button card--light'
                  >
                    {t('product.see_product')}
                  </Button>
                  {changingSize ? (
                    <>
                      <FilterSelect
                        name='size'
                        onChange={handleSizeChange}
                        placeholder={t('product.size')}
                        value={currentSize}
                        options={optionSize}
                        translation={false}
                        defaultOpen={true}
                      />
                      <Button className='button card--dark card--add-to-cart' disabled={currentSize === null || addingToCart} onClick={handleAddToCart}>
                        {addingToCart ? <LoadingOutlined /> : t('product.add_to_cart')}
                      </Button>
                    </>
                  ) : (
                    <Button className='button card--dark' onClick={handleChooseSize}>
                      {t('product.add_to_cart')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
      }
    >
      <Title
        ellipsis={{
          rows: 1,
        }}
        className='title title--h3 title--center card--title'
      >{parse(data.product_name)}</Title>
      <Paragraph
        ellipsis={{
          rows: 2,
        }}
        className='text text--center text--small card--text'
      >{parse(data.product_description)}</Paragraph>
      {(data.product_price_original && data.product_price_original > data.product_price) && (
        <Title
          ellipsis={{
            rows: 1,
          }}
          className='title title--center card--title card--price--promotion'
        >
          <span className='card--price--original'>{formattedPrice(data.product_price_original, data.product_currency, priceFloat)}</span>
          <span className='card--price--percent'>-{Math.round((data.product_price_original - data.product_price) * 100 / data.product_price_original)}%</span>
        </Title>
      )}
      <Title
        ellipsis={{
          rows: 1,
        }}
        className='title title--center title--h2 card--title'
      >
        {formattedPrice(data.product_price, data.product_currency, priceFloat)}
      </Title>
      {isFavorites ? (
        <Button
          onClick={handleAddToFavorite}
          icon={<HeartOutlined />}
          type='link'
          className='button card--favorite'
        ></Button>
      ) : (
        <Button
          icon={<HeartFilled />}
          type='link'
          className='button card--favorite'
          onClick={handleRemoveFromFavorite}
        ></Button>
      )}
    </Card>
  )
}

export default CardItem
