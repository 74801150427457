import { HANDLE_FILTERS_MODEL, HANDLE_FILTERS_CATALOG } from './index'

export const ChangeFiltersModelAction: Types.ActionFactory<State.Filters> = (payload) => ({
  type: HANDLE_FILTERS_MODEL,
  payload,
})

export const ChangeFiltersCatalogAction: Types.ActionFactory<State.Filters> = (payload) => ({
  type: HANDLE_FILTERS_CATALOG,
  payload,
})
