import React, { useEffect } from 'react'
import Select from 'antd/lib/select'
import useCustomTranslation from 'src/utils/translation'

const { Option } = Select

interface SelectProps {
  placeholder: string
  options: any
  translation?: boolean
  name: string
  value?: any
  onChange(e: any, name: string): void
  defaultOpen?: boolean
  customStyle?: any
}

const FilterSelect: React.FunctionComponent<SelectProps> = (props) => {
  const { t } = useCustomTranslation()
  const { options, translation = true, placeholder, name, value, defaultOpen = false, customStyle = {} } = props

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const filteredOptions = options.filter((one) => one.value === value)
      if (filteredOptions.length !== 1) {
        props.onChange(undefined, name)
      }
    }
    // eslint-disable-next-line
  }, [options, value])

  const getPopupContainer = (trigger) => {
    const closest = trigger.closest('.ant-layout-content')
    return closest || document.body
  }

  const filteredOptions = options.filter((one) => one.value === value)

  return (
    <>
      <Select
        size='large'
        placeholder={placeholder ?? ''}
        className='select select--container'
        value={filteredOptions.length === 1 ? value : null}
        onClick={(e: any) => e.stopPropagation()}
        onChange={(e: any) => props.onChange(e, name)}
        defaultOpen={defaultOpen}
        autoFocus={defaultOpen}
        getPopupContainer={getPopupContainer}
        style={customStyle}
      >
        {options.map((option: { label: string; value: string }, optionKey: number) => (
          <Option key={optionKey} value={option.value}>{translation ? t(option.label) : option.label}</Option>
        ))}
      </Select>
    </>
  )
}

export default FilterSelect
