// @ts-nocheck
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import React from 'react'

export default function Loader() {
    return (
        <Row justify='space-around' align='middle' className='App--loading'>
            <Col justify='space-around' align='middle'>
                <Spin size='large' />
            </Col>
        </Row>
    )
}
