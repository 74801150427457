import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Slider from 'react-slick'

import CarouselCard from 'src/components/card/cardCarouselGarment'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { trackEvent } from 'src/utils/tracking';
import { GetLookCall } from 'src/store/actions/look'

interface CarouselGarmentProps {
    type: 'top' | 'bottom' | 'dress' | 'outerwear',
    data: Models.Garment[],
    cardHeight?: number,
}

const CarouselGarment: React.FunctionComponent<CarouselGarmentProps> = (props) => {
  const { type, data, cardHeight } = props
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const look = useSelector((state: State.Root) => state.look?.current)

  const initialSlide = data.findIndex((garment) => (!look[type] || look[type].garment_id === garment.garment_id))
  const [currentSlide, setCurrentSlide] = useState(initialSlide)

  if (!data) {
    return <div className='model--empty'></div>
  }
  if (!data.length) {
    return <div className='model--empty'>{t('model.no_models')}</div>
  }

  const toFlat = [data]
  for (let i = data.length; i <= 6; i += data.length) {
    toFlat.push(data)
  }
  const augmentedData = toFlat.flat()

  const CarouselNextArrow = (arrowProps) => {
    const { className, onClick } = arrowProps

    const handleClick = (e) => {
      trackEvent('Swipe right', [{swipe_type: type}, augmentedData[currentSlide]], 'Swipe')
      onClick(e)
    }

    return (
      <div className={`${className} carousel-swipe--arrow carousel-swipe--right`} onClick={handleClick}>
        <RightOutlined />
      </div>
    )
  }

  const CarouselPrevArrow = (arrowProps) => {
    const { className, onClick } = arrowProps

    const handleClick = (e) => {
      trackEvent('Swipe left', [{swipe_type: type}, augmentedData[currentSlide]], 'Swipe')
      onClick(e)
    }

    return (
      <div className={`${className} carousel-swipe--arrow carousel-swipe--left`} onClick={handleClick}>
        <LeftOutlined />
      </div>
    )
  }

  const handleBeforeChange = (next) => {
    setCurrentSlide(next)
    const garment = augmentedData[next]
    trackEvent('Garment Selected', garment, 'Swipe')
    dispatch(GetLookCall({[garment.garment_type]: garment.garment_id}));
  }

  const settings = {
    lazyLoad: true,
    initialSlide: initialSlide,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 5,
    touchThreshold: 15,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 200,
    arrow: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    beforeChange: (current, next) => {
      handleBeforeChange(next)
    },
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  }
  return (
    <>
      <Slider {...settings} className='carousel-swipe carousel-swipe--container'>
        {augmentedData &&
          augmentedData.length > 0 &&
          augmentedData.map((item: Models.Garment, itemKey: number) => (
            <CarouselCard key={itemKey} garment={item} hide={itemKey === currentSlide} cardHeight={cardHeight}/>
          ))}
      </Slider>
    </>
  )
}

export default CarouselGarment
