import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import FilterSelect from '../../components/select/filter'
import { optionsFilter, optionSize, optionsColor, optionsType } from 'src/utils/data/js/options'
import { ChangeFiltersCatalogAction } from 'src/store/actions/filters'
import { handleSidebarAction } from 'src/store/actions/sidebar'
import { CloseOutlined } from '@ant-design/icons'

const FilterResponsive: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useCustomTranslation()
  const filters = useSelector((state: State.Root) => state.filters)

  const onChange = async (value: any, name: string) => {
    const newData: any = { ...filters.catalog }
    newData[name] = value
    await dispatch(ChangeFiltersCatalogAction(newData))
  }

  return (
    <>
      <Row className='filters-model filters-model--head'>
        <h2 className='filters-model--title'>{t('filter.title_product')}</h2>
        <CloseOutlined onClick={() => dispatch(handleSidebarAction(null))} />
      </Row>
      <Row gutter={24} justify='center' className='filters filters--responsive'>
        <Col className='gutter-row' span={24}>
          <FilterSelect
            onChange={onChange}
            name='sortBy'
            placeholder={t('filter.fake_sort')}
            options={optionsFilter}
          />
        </Col>
        <Col className='gutter-row' span={24}>
          <FilterSelect
            onChange={onChange}
            name='productType'
            placeholder={t('filter.fake_product_type')}
            options={optionsType}
          />
        </Col>
        <Col className='gutter-row' span={24}>
          <FilterSelect
            onChange={onChange}
            name='color'
            placeholder={t('filter.fake_color')}
            options={optionsColor}
          />
        </Col>
        <Col className='gutter-row' span={24}>
          <FilterSelect name='size' onChange={onChange} placeholder={t('filter.fake_size')} options={optionSize} />
        </Col>
      </Row>
    </>
  )
}

export default FilterResponsive
